/* ============
 * Getters for the language module
 * ============
 *
 * The getters that are available on the
 * language module.
 */

import { detect, forEach, G, reduce } from '@2l/utils'

export default {
  getSelectable: G('selectable'),
  getAvailable: G('available'),
  getSelected: G('selected'),
  getSelectedCode: G('selectedCode'),
  getRows: G('rows'),

  getActualLangs: (state) => {
    const langs = state.selectable
    const transformedLangs = {}
    forEach((lang) => {
      transformedLangs[lang.code] = lang
    }, langs)

    return transformedLangs
  },
  getDefaultLang: (state, getters, rootState) => {
    const currentLanguage = rootState.account.lang
    const lang = state.selectable.find(val => val.code === currentLanguage)

    return lang ? currentLanguage : 'en'
  },
  getSupportedProfiles: (state, getters, rootState) => {
    const { rows, available } = state
    const currentLanguage = rootState.account.lang

    if (!currentLanguage || !rows.length || !available[currentLanguage]) {
      return {
        en: 'English'
      }
    }

    const { supportedProfiles } = available[currentLanguage]

    const currentLangProfiles = reduce(supportedProfiles, (newObj, curr) => {
      const { nativeName } = detect(lang => lang.code === curr, rows)
      newObj[curr] = nativeName

      return newObj
    }, {})

    return currentLangProfiles
  }
}
