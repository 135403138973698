/* ============
 * Actions for the courses module
 * ============
 *
 * The actions that are available on the
 * courses module.
 */

import { G } from '@2l/utils'
import db from '@/lib/db'

import Transformer from '@/transformers/CoursesTransformer'

import CoursesProxy from '../../../proxies/CoursesProxy.js'

import * as types from './mutation-types.js'

const getCourseNumber = G('result.course.number')

const coursesProxy = new CoursesProxy()

export default {
  find,
  select,
  unselect,
  ensureRoadmap,
  selectRowCount,
  setLastVisitedLessonId,
  getLastVisitedLessonId,
  lessonCompleted
}

export async function ensureRoadmap ({ commit, dispatch }) {
  await dispatch('general/startLoading', {}, { root: true })

  const response = await coursesProxy.getRoadmap()
  commit(types.SET_ROADMAP, response.result)

  await dispatch('general/stopLoading', {}, { root: true })
}

export async function find ({ commit, state, dispatch }, withoutLoading) {
  if (!withoutLoading) { await dispatch('general/startLoading', {}, { root: true }) }

  const response = await coursesProxy.all()

  commit(types.FIND, Transformer.prefetch(response.result))

  return state.selectable
}

export async function select ({ commit, getters, dispatch }, { value: courseId }) {
  const { getFirstSelectableCourseId } = getters

  await dispatch('general/startLoading', {}, { root: true })

  if (!courseId) { courseId = getFirstSelectableCourseId }

  const response = await coursesProxy.find(courseId)

  const course = Transformer.fetch(response.result.course, {
    meta: {
      analytics: [
        [
          'event',
          'courses',
          'selectCourse',
          `Select a ${getCourseNumber(response)} course`,
          courseId
        ]
      ]
    }
  })

  commit(types.SELECT, course)

  await dispatch('general/stopLoading', {}, { root: true })
  return course
}

export async function lessonCompleted ({ commit, getters, dispatch }, payload) {
  await coursesProxy.validate(payload)
}

export async function clearSelected ({ commit }) {
  commit(types.UNSELECT)
}

export async function unselect ({ commit, state }) {
  const { selected } = state

  commit(types.UNSELECT, {
    meta: {
      analytics: [
        [
          'event',
          'courses',
          'unselectCourse',
          'Select an empty option of select box',
          selected && selected._id
        ]
      ]
    }
  })
}

export async function selectRowCount ({ commit }, rowCount) {
  commit(types.SET_ROW_COUNT, rowCount)
}

export async function setLastVisitedLessonId ({ commit }, params) {
  const { courseId, lesson } = params
  const { _id: lessonId } = lesson

  await db.set('lessons', courseId, lessonId)
  await commit(types.SET_LAST_VISITED_LESSON_ID, lessonId)
}

export async function getLastVisitedLessonId ({ commit }, courseId) {
  const lastVisitedLessonId = await db.get('lessons', courseId)

  await commit(types.SET_LAST_VISITED_LESSON_ID, lastVisitedLessonId)
}
