import { identity } from '@2l/utils';

let _referrer: string;
let _url: URL

try {
  _url = new URL(window.location.href)
  _referrer = window.document.referrer
} catch (_) {}

const url = () => _url

function param(name: string, defaultValue = '') {
  return url().searchParams.get(name) || defaultValue;
}

function is(name: string, test: string) {
  return param(name).toLowerCase() === test;
}

function firstOfParam(names: string[], defaultValue = '') {
  for (const n of names)
    if (url().searchParams.has(n))
      return url().searchParams.get(n) || defaultValue;

  return defaultValue;
}

function getLastPart(value?: string | undefined, separator = '.') {
  return value ? value.split(separator).pop() : value;
}

function setUrl (u: URL) {
  _url = u
}

function setReferrer (ref: string) {
  _referrer = ref
}

const getReferrer = () => _referrer

export { is, param, firstOfParam, getLastPart, getReferrer, setUrl };
export default { is, param, firstOfParam, setUrl };
