import BaseProxy from './Proxy.js'

class MeProxy extends BaseProxy {
  /**
   * The constructor for the MeProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/me', parameters)
  }

  /**
   * Method used to get user stats
   *
   * @returns {Promise} The result in a promise.
   */
  stats () {
    return this.submit('get', `${this.endpoint}/stats`)
  }

  statsByPeriod () {
    return this.submit('get', `${this.endpoint}/stats/words`)
  }
}

export default MeProxy
