import { env, firstOf, G, identity } from '@2l/utils'

const APPLE_CLIENT_WEB_ID = env(
  'APPLE_CLIENT_WEB_ID',
  'com.ewa.ewaapp.webapp'
)

const path = '/auth/callback/apple'
const url = new URL(path, window.location.origin)
const redirectURI = url.toString()
const getToken = G('authorization.code')
const getErrorMessage = firstOf(
  G('error'),
  G('details'),
  identity('alert_something_went_wrong')
)

class Apple {
  async authenticate () {
    const data = {
      clientId: APPLE_CLIENT_WEB_ID,
      scope: 'name email',
      redirectURI,
      usePopup: true
    }

    try {
      await window.AppleID.auth.init(data)
      const response = await window.AppleID.auth.signIn()
      return getToken(response)
    } catch (error) {
      return Promise.reject(new Error(getErrorMessage(error)))
    }
  }
}

export default Apple
