<template>
  <div class="spinner" v-show="isLoading">
    <div class="v-spinner">
      <div class="v-skew" v-bind:style="spinnerStyle"> </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/stylus/components/spinner.styl'
import { computed, reactive } from 'vue'

import { useStore } from 'vuex'

const store = useStore()

const isLoading = computed(() => store.getters['general/isLoading'])

const spinnerStyle = reactive({
  height: 100,
  width: 37
})
</script>
