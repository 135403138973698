/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const REGISTER = 'REGISTER'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const CHECK = 'CHECK'

export default {
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK
}
