/* ============
 * State of the general module
 * ============
 *
 * The initial state of the general module.
 */

export default {
  rows: [],
  available: {},
  selectable: [],

  version: null,
  subscribed: false,
  selectedCode: null
}
