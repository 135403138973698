/* ============
 * State of the modal module
 * ============
 *
 * The initial state of the modal module.
 */

export default {
  visible: false,
  component: null,
  options: {}
}
