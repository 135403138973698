import log from 'loglevel';
import random from 'random';

import Cookies from 'js-cookie';

import * as rc from '@firebase/remote-config';
import { FirebaseApp, initializeApp } from '@firebase/app';

import { ensureFunction, extend, groupBy, identity, reduce, reduceKeys, sum, when } from '@2l/utils';
import { EventEmitter } from 'tsee';
import { Experiment, LOCAL_SOURCE, RawExperiment, RawRemoteConfigParam, SOURCE_MODE } from './interfaces';
import { Value } from './value';
import * as u from './../links/util';
// @ts-ignore
import rawConfig from './_rc.json';

const TTL = 36e3; // 1h
const reExperimentName = /^web_(.*)_[0-9]{6}/gm

export default class ConfigController extends EventEmitter {
  private cookieKeyExperiments = '_ewa.experiments';
  private storageKeyConfig = '_ewa.remote_config';
  private urlKeyConfig = 'urlKeyExperiments';

  sourceMode: SOURCE_MODE;
  layers = ['landing', 'conversion', 'class', 'store', 'external'];
  config: { [key: string]: any };
  customConfig: { [key: string]: any } = {};
  experiments: { [key: string]: Experiment } = {};
  syncedAt = 0;
  firebase: FirebaseApp;
  localConfig: Record<string, rc.Value>;
  rawExperiments: Array<RawExperiment> = [];

  constructor() {
    super();
    this.config = this.restoreConfig() || {};
    this.sourceMode = LOCAL_SOURCE; // FIXME: class should support multiple adapters (local, custom and firebase)

    this.firebase = initializeApp({
      apiKey: "AIzaSyBVWDxJYO1nkb-FoRP7MOTjmLrtVGQSQdE",
      authDomain: "ewaapp-140010.firebaseapp.com",
      databaseURL: "https://ewaapp-140010.firebaseio.com",
      projectId: "ewaapp-140010",
      storageBucket: "ewaapp-140010.appspot.com",
      messagingSenderId: "787677384866",
      appId: "1:787677384866:web:faedd0092d08c432",
      measurementId: "G-YRQ0RJKXBZ",
    });

    this.localConfig = reduceKeys(rawConfig.parameters, (cfg: Record<string, rc.Value>, v: RawRemoteConfigParam, k: string) => {
      const rawValue = v.conditionalValues?.Web?.value;

      if (!rawValue)
        return cfg;

      cfg[k] = new Value('static', rawValue)

      return cfg
    });

    this.applyConfig(this.localConfig);
  }

  async init() {
    if (this.sourceMode === LOCAL_SOURCE)
      return await this.sync()

    const cfg = rc.getRemoteConfig(this.firebase);
    cfg.settings = {
      minimumFetchIntervalMillis: TTL,
      fetchTimeoutMillis: 10000 // 10s
    };

    cfg.defaultConfig = reduceKeys(this.localConfig, (cfg: { [key: string]: string | number | boolean },
                                                      v: Value, k: string) => {
      cfg[k] = v.asString()
      return cfg;
    });

    await rc.fetchAndActivate(cfg);
    await this.sync();
  }

  async sync(forceExperimentId?: string, forceOptionId?: string, experimentLayer?: string) {
    let cfg;
    try {
      if (this.sourceMode !== LOCAL_SOURCE)
        cfg = await rc.getAll(rc.getRemoteConfig(this.firebase));
    } catch (e) {
      log.error(e)
      return;
    }

    return this.applyConfig(cfg, forceExperimentId, forceOptionId, experimentLayer);
  }

  async attachLayer(layer: string, context: object, forceExperimentId?: string, forceOptionId?: string, forceJoin?: boolean, externalId?: string) {
    this.applyConfig(this.localConfig, forceExperimentId, '', layer, context);

    const grouped: any[] = groupBy('type', this.rawExperiments);
    const userId = externalId || ''
    const userIdHash = hashFnv32a(userId)

    for (const experimentType in grouped) {
      const group: Array<RawExperiment> = grouped[experimentType];
      const maxValue = forceJoin ?  1 : Math.max(1, sum('portion', group));
      const rnd = random.float(0, maxValue);

      let cursor = 0;

      for (const rawExperiment of group) {
        const endValue = cursor + rawExperiment.portion;
        const ex: Experiment = {
          experimentId: rawExperiment.experimentId,
          type: rawExperiment.type,
        };
        this.experiments[ex.type] = ex;

          // FIXME: should consider maxValue to improve segregation?
          if ((rnd >= cursor && rnd <= endValue) || forceJoin) {
            const optionNames = Object.keys(rawExperiment.options);

            if (forceOptionId && !optionNames.includes(forceOptionId))
              throw new Error(`Option ${forceOptionId} doesn't allow for ${rawExperiment.experimentId}`);

            const groupId = userIdHash % optionNames.length
            const optionId = forceOptionId || optionNames[groupId];
            const { values } = rawExperiment.options[optionId];

            ex.optionId = optionId;

            if (typeof values === 'string') {
              try {
                  ex.values = JSON.parse(values);
              } catch (e) {
                  log.error(e);
              }
            } else if (typeof values === 'object') {
                ex.values = values;
            }

            if (rawExperiment.ttlMillis > 0) {
                ex.expiredAt = Date.now() + rawExperiment.ttlMillis;
            }

            log.info(`User has joined into experiment ${ex.experimentId}/${optionId}`);

            break; // break on client has joined into an experiment
          }
          cursor = endValue;
      }
    }

    this.updateExperiments(); // save experiments to cookie
    await this.sync();
  }

  applyConfig(cfg?: Record<string, rc.Value>, forceExperimentId?: string, forceOptionId?: string, experimentLayer?: string, context?: object) {
    if (!cfg)
      cfg = this.localConfig;

    const rawExperiments: Array<RawExperiment> = [];
    const remoteConfig: { [key: string]: any } = {};

    for (const key in cfg) {
      if (!key.startsWith('experiment'))
        continue;

      const val = cfg[key].asString();
      try {
        remoteConfig[key] = JSON.parse(val);
      } catch (_) {
        remoteConfig[key] = val;
      }

      const experiment: RawExperiment = JSON.parse(cfg[key].asString());
      // skip non-web experiments
      if (!experiment.experimentId.startsWith('web'))
        continue;

      // skip unsupported layers
      if (!this.layers.includes(experiment.type))
        continue;

      // don't apply unexpected layers
      if (experiment.type !== experimentLayer)
        continue;

      // don't process an experiment that has no correct name
      if (!experiment.experimentId.match(reExperimentName))
        continue;

      // don't process disabled experiment
      if (experiment.portion === 0)
        continue;

      const check: (ctx: any) => boolean = ensureFunction(
        experiment.conditions || identity(), when
      );

      // don't process if conditions are not pass
      if (check && !check(context))
        continue

      const externalNotValid = experiment.type === 'external' && !forceExperimentId;
      const joinedExperiment = this.experiments[experiment.type]
        && this.experiments[experiment.type].experimentId === forceExperimentId;

      if (externalNotValid)
        continue

      // force join a user into an experiment
      if (!this.experiments[experiment.type] && !forceExperimentId
        || experiment.experimentId === forceExperimentId && !joinedExperiment)
        rawExperiments.push(experiment);
    }

    this.rawExperiments = rawExperiments;
    this.updateExperiments(); // save experiments to cookie

    this.config = this.updateConfig(extend(remoteConfig, this.customConfig));
    this.syncedAt = Date.now();

    this.emit('sync', {
      config: this.config,
      experiments: this.experiments
    });
  }

  getRemoteConfig(): { [key: string]: any } {
    return this.config;
  }

  async syncAndGetRemoteConfig(force = false): Promise<{ [key: string]: any }> {
    if ((Date.now() - this.syncedAt) >= TTL || force)
      await this.sync();

    return this.config;
  }

  async setCustomConfigKey(key: string, value: any): Promise<{ [key: string]: any }> {
    this.customConfig[key] = value;

    return this.getRemoteConfig();
  }

  async setCustomConfig(value?: { [key: string]: any }): Promise<{ [key: string]: any }> {
    if (value)
      this.customConfig = value;

    return this.syncAndGetRemoteConfig();
  }

  getActiveExperiments(): Array<Experiment> {
    const types = Object.keys(this.experiments);

    return reduce(types, (reducer: Array<Experiment>, type: string) => {
      const ex = this.experiments[type];
      if (ex.optionId)
        reducer.push(ex);

      return reducer;
    });
  }

  markAsJoined(experimentType: string) {
    if (this.experiments[experimentType])
      this.experiments[experimentType].joined = true;

    this.updateExperiments();
  }

  async markAsLeaved(experimentType?: string) {
    const types = experimentType ? [experimentType] : Object.keys(this.experiments);

    for (const t of types) {
      if (!this.experiments[t])
        continue;

      this.experiments[t].joined = false;
      this.experiments[t].optionId = '';
    }

    this.updateExperiments();
    await this.sync();
  }

  async resetExperiments() {
    Cookies.remove(this.cookieKeyExperiments);
    this.experiments = {};
    await this.sync();
  }

  async resetExperiment(experimentType: string) {
    const cookie = JSON.parse(Cookies.get(this.cookieKeyExperiments) as string)
    delete cookie[experimentType]
    Cookies.set(this.cookieKeyExperiments, JSON.stringify(cookie));
    delete this.experiments[experimentType];
  }

  private updateExperiments() {
    Cookies.set(this.cookieKeyExperiments, JSON.stringify(this.experiments));
  }

  private updateConfig(config: { [key: string]: any }): { [key: string]: any } {
    config = this.extendWithExperimentParams(config);
    localStorage.setItem(this.storageKeyConfig, JSON.stringify(config));
    return config;
  }

  private getUrlConfig() {
    const urlConfig = u.param(this.urlKeyConfig);

    if (!urlConfig || urlConfig.length < 20)
      return;

    const prepared = urlConfig.slice(1, -1);

    try {
      JSON.parse(prepared);
    } catch (e) {
      return log.info('URL config is not correct. Error: ', e);
    }

    return prepared;
  }

  private restoreConfig(): { [key: string]: any } | undefined {
    try {
      const entries = this.getUrlConfig()
        || Cookies.get(this.cookieKeyExperiments);

      if (entries)
        this.experiments = JSON.parse(entries);

      log.info('[EWA.ANALYTICS] unpack the following experiments: %O',
        this.experiments);
      // eslint-disable-next-line no-empty
    } finally {
    }

    const value = localStorage.getItem(this.storageKeyConfig);

    if (!value)
      return;

    try {
      return this.extendWithExperimentParams(JSON.parse(value));
    } catch (e) {
      log.error(e);
    }
  }

  private extendWithExperimentParams(config: { [key: string]: any }): { [key: string]: any } {
    for(const ex in config){
      if(config[ex].portion === 0){
        delete config[ex];
      }
    }

    if (!this.experiments)
      return config;

    for (const type in this.experiments) {
      const ex = this.experiments[type];
      const isActualExperiment = Object.values(config).find((val) => val.experimentId === ex.experimentId)

      if(!isActualExperiment) {
        return this.resetExperiment(ex.type)
      }

      if (!ex.optionId)
        continue;

      if (typeof ex.values === 'object')
        Object.assign(config, ex.values);
    }

    return config;
  }
}

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer}
 */
function hashFnv32a(str, seed) {
  /*jshint bitwise:false */
  var i, l,
    hval = (seed === undefined) ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) ^ (hval << 4) ^ (hval << 7) ^ (hval << 8) ^ (hval << 24);
  }

  return hval >>> 0;
}
