import { EventType } from '../../events';

const mapping: { [index:string]: string } = {
  [EventType.START_TRIAL]: 'ActivatedTrialSubscription',
  [EventType.PURCHASE]: 'PurchasedSubscription'
};


export function getEventName(eventName: string) {
  return mapping[eventName] || eventName;
}
