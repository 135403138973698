/* ============
 * Getters for the materials module
 * ============
 *
 * The getters that are available on the
 * materials module.
 */
import { G } from '@2l/utils'

export default {
  getMaterialIds: G('materialIds')
}
