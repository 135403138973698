<template>
  <input class="bottom__progress"
         :style="rangeProgressGradient"
         type="range"
         v-model="progress">
</template>

<script>
// eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiRange'
})
</script>
<script setup>
// eslint-disable-next-line
import { defineProps, defineEmits, computed } from 'vue'
const emit = defineEmits(['update:progress'])

const props = defineProps({
  progress: {
    type: Number,
    default: 0
  }
})

const progress = computed({
  get () {
    return props.progress
  },
  set (value) {
    emit('update:progress', value)
  }
})

const rangeProgressGradient = computed(() => {
  return {
    background:
      `linear-gradient(to right, #00B2FF,
    ${progress.value}%,
    #D5DBE3 ${progress.value}% ${100 - progress.value}%)`
  }
})
</script>

<style lang="stylus" scoped>
input[type='range'] {
  cursor: pointer;
  -webkit-appearance: none !important;
  width: 100%;
  height: 2px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background: #00B2FF;
  width: 12px;
  height: 12px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}
</style>
