import { Adapter, Event } from '../adapter';
import { CRMOptions, EventData } from '../crm/interfaces';
import { extend, when, pick, G, oneOf, identity } from '@2l/utils';

export default class CRMAdapter extends Adapter {
  static stream: Uppercase<string> = 'CRM';
  api_url?: any;

  constructor(options?: CRMOptions) {
    super(options);

    this.api_url = options?.crm?.api_url;
  }

  async _init() {
    this.client = true;
  }

  _invoke(event: Event) {
    const params: EventData = extend(event.context, event.value, { name: event.name });

    if (isPermitted(params))
      return this.pushEvent(params);
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;
  }

  pushEvent(params: {[index: string]: any}) {
    return fetch(this.api_url, {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify(validateParams(params))
    });
  }
}

const isPermitted = when({
  'name': 'subscriptions',
  'subtype': [
    'checkout_visited',
    'visited',
    'tapped',
    'paypalTapped',
    'cloudpaymentsTapped',
    'cardpayTapped',
    'applepayTapped',
    'googlepayTapped',
    'linkTapped',
    'activated'
  ]
});

const defineSubtype = oneOf({
  'payButtonTapped': when({
    'subtype': [
      'paypalTapped',
      'cloudpaymentsTapped',
      'cardpayTapped',
      'applepayTapped',
      'googlepayTapped',
      'linkTapped'
    ]
  }),
  'trial': when({ 'subtype': 'activated', 'trial_days.gte': 1 }),
  'one-time': when({ 'subtype': 'activated', 'plan_type': 'Unlimited' }),
  'purchased': when({ 'subtype': 'activated'})
}, G('subtype'))

const validateParams = pick({
  'adid': G('event_id'),
  'web_uuid': G('web_uuid'),
  'userId': G('customer_user_id'),
  'customFields.event_name': oneOf({
    'purchased': { 'subtype': 'activated' }
  }, G('name')),
  'customFields.type': G('type'),
  'customFields.subtype': defineSubtype,
  'product_id': G('af_content'),
  'customFields.campaign_name': G('campaign_name'),
  'customFields.message_id': G('message_id'),
  'customFields.paid': G('paid'),
  'customFields.bill_id': G('bill_id'),
  'customFields.revenue': G('revenue'),
  'customFields.currency': G('currency'),
  'customFields.push_source': identity('web')
});
