import { v4 as uuidv4 } from 'uuid';
import { G, pick, firstOf, identity, compose, L, all, when, invoke } from '@2l/utils';
import { SchemaDSLInterface } from './schema';
import * as u from './links/util';

const needUrlParams = firstOf(
  when([
    { eventName: 'landing', subtype: 'visited' },
    { eventName: 'subscriptions', subtype: 'visited' }
  ]),
  compose(G('eventName'), invoke('includes', 'onboarding')))

export default function schema(s: SchemaDSLInterface) {
  const { CUSTOM_EVENT, START_TRIAL, PURCHASE } = s.events;

  s.adapter(s.FacebookAdapter, a => {
    a.extend(pick({
      external_id: firstOf(G('user._id'), G('user.id'), G('userId'))
    }));

    a.event(START_TRIAL, ['StartTrial', 'Subscribe'], {
      currency: G('currency'),
      value: identity(0), // set zero always

      predicted_ltv: G('revenue') // use pre-calculated coeff
    });

    a.event(PURCHASE, ['Purchase'], {
      currency: G('currency'),
      value: G('revenue')
    });
  });

  s.adapter(s.AdjustAdapter, a => {
    a.extend({
      event_id: firstOf(G('eventId'), uuidv4),
      logged_in: G('loggedIn'),
      source_page: G('sourcePage'),
      active_profile: G('activeProfile'),
      native_language: G('nativeLanguage'),
      environment: firstOf(G('environment'), identity('development')),
      customer_user_id: G('customerUserId'),

      creative_id: all(needUrlParams, u.param('af_ad_id')),
      creative: all(needUrlParams, u.firstOfParam(['af_ad', 'creative', 'creative_name'])),
      adgroup_id: all(needUrlParams, u.param('af_adset_id')),
      adgroup: all(needUrlParams, u.firstOfParam(['af_adset', 'adgroup', 'adgroup_name'])),
      campaign_id: all(needUrlParams, u.firstOfParam(['af_c_id', 'cid'])),
      campaign: all(needUrlParams, u.firstOfParam(['c', 'campaign', 'campaign_name', 'utm_campaign'])),
      utm_source: all(needUrlParams, u.param('utm_source')),
      utm_medium: all(needUrlParams, u.param('utm_medium'))
    });

    a.event(CUSTOM_EVENT, G('eventName'));
  });

  s.adapter(s.YandexAdapter, a => {
    a.extend({
      event_id: firstOf(G('eventId'), uuidv4),
      logged_in: G('loggedIn'),
      source_page: G('sourcePage'),
      active_profile: G('activeProfile'),
      native_language: G('nativeLanguage'),
      environment: firstOf(G('environment'), identity('development'))
    });

    a.event(CUSTOM_EVENT, G('eventName'));
  });

  s.adapter(s.GoogleAnalyticsAdapter, a => {
    a.event(CUSTOM_EVENT, G('eventName'));
  });

  s.adapter(s.TwitterAdapter, a => {
    a.extend(pick({
      external_id: firstOf(G('user._id'), G('user.id'), G('userId'))
    }));

    a.event(START_TRIAL, ['StartTrial', 'Purchase'], {
      currency: G('currency'),
      value: G('revenue'),
      email_address: G('customerEmail')
    });

    a.event(PURCHASE, ['Purchase'], {
      currency: G('currency'),
      value: G('revenue'),
      email_address: G('customerEmail')
    });

    a.event(CUSTOM_EVENT, G('eventName'), {
      currency: G('currency'),
      value: G('revenue'),
      email_address: G('customerEmail')
    });
  });

  s.adapter(s.OceanengineAdapter, a => {
    a.extend(pick({
      external_id: firstOf(G('user._id'), G('user.id'), G('userId'))
    }));

    a.event(START_TRIAL, ['StartTrial', 'Purchase'], {
      product_price: G('revenue'),
    });

    a.event(PURCHASE, ['Purchase'], {
      product_price: G('revenue'),
    });

    a.event(CUSTOM_EVENT, G('eventName'));
  });

  s.adapter(s.CRMAdapter, a => {
    a.extend({
      web_uuid: compose(L, G('web_uuid')),
      event_id: firstOf(G('eventId'), uuidv4),
      logged_in: G('loggedIn'),
      source_page: G('sourcePage'),
      active_profile: G('activeProfile'),
      native_language: G('nativeLanguage'),
      environment: firstOf(G('environment'), identity('development')),
      customer_user_id: G('customerUserId')
    });

    a.event(CUSTOM_EVENT, G('eventName'));
  });
}
