import { G, env, firstOf, identity } from '@2l/utils'

const GOOGLE_CLIENT_ID = env(
  'GOOGLE_CLIENT_WEB_ID',
  '787677384866-sbrorj1e4451nnl8g8rdihcfvep54ccb.apps.googleusercontent.com'
)

const getToken = G('access_token')
const getErrorMessage = firstOf(
  G('error'),
  G('details'),
  identity('alert_something_went_wrong')
)

class Google {
  async authenticate () {
    return new Promise((resolve, reject) => {
      const handleSuccessResponse = (response) => {
        const token = getToken(response)
        if (!token) {
          const errorMessage = getErrorMessage(response)

          reject(new Error(errorMessage))
        }
        resolve(token)
      }

      const handleErrorResponse = (error) => {
        reject(error)
      }

      const data = {
        client_id: GOOGLE_CLIENT_ID,
        scope: 'profile',
        callback: handleSuccessResponse,
        error_callback: handleErrorResponse
      }

      const client = window.google.accounts.oauth2.initTokenClient(data)
      client.requestAccessToken()
    })
  }
}

export default Google
