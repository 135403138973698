import BaseProxy from './Proxy.js'

class CheckoutProxy extends BaseProxy {
  constructor (parameters = {}) {
    super('api/v18/checkout', parameters)
  }

  getLink () {
    return this.submit('post', `${this.endpoint}/twocheckout`)
  }
}

export default CheckoutProxy
