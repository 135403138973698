/* ============
 * Getters for the offers module
 * ============
 *
 * The getters that are available on the
 * offers module.
 */

import { G, get, firstOf } from '@2l/utils'

export default {
  getInfoPromoDay: G('infoPromoDay'),
  getInfoNoTrial: G('infoCheckoutNoTrial'),
  getInfoCheckoutNoTrialTwoCheckout: G('infoCheckoutNoTrialTwoCheckout'),
  getWidgets: G('widgets'),
  getOfferTrialDays: G('offer.trialPeriod'),
  getPlanCards: G('offerCards'),
  getOfferItem: G('offer'),
  getPlans: G('plans'),
  getCardReviews: G('cardReviews'),
  getInfo: G('info'),
  getApplicationIcons: G('applicationIcons'),
  storeCards: G('storeCards'),
  getInfoCheckout: G('infoCheckout'),
  getInfoCheckoutV4: G('infoCheckoutV4'),
  getCloudPaymentsPlan: G('cloudPaymentPlan'),
  getOnMonthPrice: G('onMonthPrice'),
  getPromoUser: G('promoUser'),
  getCards: G('paymentCards'),

  getOfferId: G('offer._id'),
  getOfferName: firstOf(
    G('offer.description'),
    G('offer.name')
  ),

  getOfferCurrency: G('offer.userCurrency'),
  getInfoBabbel: G('infoBabbel'),
  infoAboutSecure: (state) => {
    const { info } = state

    return info[1]
  },
  getOfferPurchasePrice: (state) => {
    const { offer } = state

    if (!offer) { return }

    const {
      userCurrency: currency,
      period
    } = offer

    const monthPrice = get(offer, `monthPrices.${currency}`)
    const currencyPrice = get(offer, `currencyPrices.${currency}`)

    return monthPrice ? monthPrice * period : currencyPrice
  },
  getTierType: G('tierType'),
  getMessageId: G('messageId'),
  getCampaignName: G('campaignName'),
  getIsFirstTimeClosingPayment: G('isFirstTimeClosingPayment'),
  getSkeletonLoadStatus: G('isSkeletonLoad'),
  getPlanIdErrorStatus: G('planIdErrorStatus')
}
