/* ============
 * Mutations for the notification modul
 * ============
 *
 * The mutations that are available on the
 * notification module.
 */

import {
  PUSH,

  STOP_PULLING,
  START_PULLING,

  FILL_ACTIVE_QUEUE,
  CLEAR_ACTIVE_QUEUE

} from './mutation-types.js'

export default {
  [PUSH] (state, entry) {
    const timeOffset = entry.time || 1500

    entry.createdAt = Date.now()
    entry.expiredAt = Date.now() + timeOffset

    state.waitingQueue.push(entry)
  },

  [START_PULLING] (state) {
    state.pulling = true
  },

  [STOP_PULLING] (state) {
    state.pulling = false
  },

  [FILL_ACTIVE_QUEUE] (state) {
    const notifications = state.waitingQueue
    if (state.activeQueue.length >= 3) { return }

    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i]

      state.activeQueue.push(notification)
      state.waitingQueue.splice(i, 1)
    }
  },

  [CLEAR_ACTIVE_QUEUE] (state) {
    const notifications = []
    const now = new Date()

    for (const notification of state.activeQueue) {
      const { expiredAt } = notifications

      if (expiredAt < now) { notifications.push(notification) }
    }

    state.activeQueue = notifications
  }
}
