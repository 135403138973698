/* ============
 * Mutations for the general module
 * ============
 *
 * The mutations that are available on the
 * general module.
 */

import {
  START_LOADING,
  STOP_LOADING,

  SET_LOCALE_LOADED
} from './mutation-types.js'

export default {
  [START_LOADING] (state) {
    state.loading = true
  },

  [STOP_LOADING] (state) {
    state.loading = false
  },

  [SET_LOCALE_LOADED] (state) {
    state.isLocaleLoaded = true
  }
}
