/* ============
 * Getters for modal module
 * ============
 *
 * The getters that are available on the
 * modal module.
 */

import { G, eql } from '@2l/utils'

export default {
  isVisible: eql('visible', true),
  getComponent: G('component'),
  isRegistrationTab: eql('component', 'registration'),
  getOptions: G('options'),
  getModalClass: G('options.modalClass'),
  isMenuClass: eql('options.modalClass', 'menu')
}
