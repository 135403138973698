/* ============
 * State of the general module
 * ============
 *
 * The initial state of the general module.
 */

export default {
  marker: null,
  loading: false,
  isLocaleLoaded: false,
  localizationSubscribe: false
}
