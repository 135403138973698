import Cookies from 'js-cookie';
import { firstOf } from '@2l/utils';
import { param } from '../../links/util'

export const fbc = firstOf(
  () => Cookies.get('_fbc'),
  () => param('fbclid')
);

export const fbp = () => Cookies.get('_fbp');
