/* ============
 * Language Transformer
 * ============
 *
 * Assign target url for language by code
 */

import Transformer from './Transformer.js'

// eslint-disable-next-line
const [WEB_APP_PROTOCOL, WEB_APP_DOMAIN] = __WEB_APP_URL__.split('://');

export default class LanguageTransformer extends Transformer {
  /**
   * Method used to transform a fetched materials.
   *
   * @param account The fetched materials.
   *
   * @returns {Object} The transformed materials.
   */
  static fetch (language) {
    const location = LanguageTransformer.getUrlByLangCode(language.code)

    return {
      get url () {
        const { pathname } = window.location

        return location + pathname
      },
      get defaultUrl () {
        return location
      },
      ...language
    }
  }

  /**
   * Returns url to go to the localized version for given lang code
   *
   * @param  {String} code
   * @return {String}
   */
  static getUrlByLangCode (code) {
    return `${WEB_APP_PROTOCOL}://${code}.${WEB_APP_DOMAIN}`
  }
}
