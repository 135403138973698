import log from 'loglevel'

import { v4 as uuid } from 'uuid';
import { EventEmitter } from 'tsee';
import { extend, isNone, compose, all, G, not, eql, cleanUpObject } from '@2l/utils';

import mapping from './mapping';
import { EventType } from './events';
import { Adapter, Options } from './adapters/adapter';
import { ConfigController, UserController } from './controllers';
import { Schema, SchemaDSL } from './schema';
import type { SchemaDSLInterface } from './schema';

import YandexMetrikaAdapter from './adapters/yandex/yandex-adapter';
import AdjustAdapter from './adapters/adjust/adjust-adapter';
import { Context } from './interfaces';
import * as u from './links/util';
const trackers: { [index: string]: Tracker } = {};

const checkNotEqlId = compose(all(
  G('customerUserId'),
  not(eql('customerUserId', (item: { external_id: any; }) => item.external_id)),
  G('external_id'),
));

export default class Tracker extends EventEmitter {
  name: string;
  external_id?: string | undefined;

  schema: Schema;
  userController: UserController;
  configController: ConfigController;
  readyAdapters: number;

  private options: Options;
  private firedEvents: { [key: string]: any[] };
  private customConfig?: { [key: string]: any };

  constructor(name: string, schema: Schema, options: Options, customConfig?: { [key: string]: any }) {
    super();
    this.name = name;
    this.schema = schema;
    this.userController = new UserController();
    this.configController = new ConfigController();

    this.options = options
    this.firedEvents = {};
    this.customConfig = customConfig;

    this.readyAdapters = 0;

    this.schema.on('adapter.ready', (adapter) => {
      this.fire('schema.adapter.ready', schema.readyAdapters);
      this.fire(`schema.adapter.ready.${adapter.getStream()}`, adapter);
      this.readyAdapters += 1;

      if (adapter instanceof AdjustAdapter)
        this.userController.book({
          adjustWebUUID: adapter.getFingerPrintId(),
          adjustTrackerId: adapter.getVisitorId(),
          force: true
        })
          .catch(log.error)
          .then(externalId => externalId ? this.external_id = externalId : null);

      adapter.drain().catch(log.error).then(() => {
        log.info("[%s] events have been successfully drained", adapter.getStream());
      });
    });

    this.schema.on('schema.adapter.all.ready', () => {
      this.fire('schema.adapter.all.ready');
    });

    this.configController.on('sync', ({ config, experiments }) => {
      this.fire('config.controller.sync', { config, experiments });
    });
  }

  async init() { // TODO: should separate options for tracker, schema and adapter[s]
    const { options, customConfig } = this;

    await Promise.all([
      this.userController.init(options?.external_id),
      this.configController.setCustomConfig(customConfig),
      this.configController.init()
    ]);

    if (options?.external_id)
      this.external_id = options.external_id;
    else if (options && !options.external_id)
      this.external_id = options.external_id = await this.userController.book();

    this.schema.init(this.options);
  }

  get initialized(): boolean {
     return this.readyAdapters > 0;
  }

  static getTracker(name: string) {
    if (!trackers[name])
      throw new Error('Tracker has not initialized yet');

    return trackers[name];
  }

  async triggerLayer(layer: string, context: object, forceExperimentId?: string, forceOptionId?: string, forceJoin?: boolean) {
    await this.configController.attachLayer(layer, context, forceExperimentId, forceOptionId, forceJoin, this.getFingerPrintId());

    if (this.initialized)
      return this.processExperiments(context);

    this.on('schema.adapter.ready.ADJUST', () => {
      return this.processExperiments(context);
    });
  }

  static GetTrackerByMediaSource(mediaSource: string, trackingConfig: any = {}): Tracker {
    for (const k in trackingConfig) {
      trackingConfig[k] = cleanUpObject(trackingConfig[k]);
    }

    switch (mediaSource) {
      case 'facebook':
        return Tracker.configure({
          facebook: trackingConfig.facebook || { enabled: true },
          twitter: {
            enabled: false
          },
          google: {
            enabled: false
          },
          oceanengine: {
            enabled: false
          }
        });
      case 'google':
      case 'google_ads':
        return Tracker.configure({
          google: trackingConfig.google || {},
          twitter: {
            enabled: false
          },
          oceanengine: {
            enabled: false
          },
          facebook: {
            enabled: false
          }
        });
      case 'oceanengine':
        return Tracker.getChineseTracker(trackingConfig);
      default:
        return Tracker.getDefaultTracker(trackingConfig)
    }
  }

  static getDefaultTracker(trackingConfig: any = {}): Tracker {
    return Tracker.configure({
      google: trackingConfig.google || {},
      twitter: trackingConfig.twitter || {},
      facebook: trackingConfig.facebook || {},
      oceanengine: extend({ enabled: false }, trackingConfig.oceanengine)
    });
  }

  static getChineseTracker(trackingConfig: any = {}): Tracker {
    return Tracker.configure({
      google: trackingConfig.google || {},
      twitter: trackingConfig.twitter || {},
      facebook: trackingConfig.facebook || {},
      oceanengine: extend({ enabled: true }, trackingConfig.oceanengine || {})
    });
  }

  private static configure(trackingConfig: any = {}, customConfig: any = {}): Tracker {
    const environment = __ENVIRONMENT__.includes('production')
      ? 'production' : 'development';

    const edition = __EDITION__ !== 'kids' ? 'standard' : 'kids';
    return Tracker.ensureTrackerInstance('default', mapping, extend({
      edition,
      environment,
      adjust: {
        app_token: __ADJUST_APP_TOKEN__,
        environment: environment !== 'production' ?
          'sandbox' : 'production'
      },
      oceanengine: {
        assets_id: __OCEANENGINE_ASSETS_ID__
      },
      facebook: extend({
        pixel_id: u.param('fb_pixel_id', __FACEBOOK_PIXEL_ID__),
        base_url: __FACEBOOK_CONVERSION_API_ENDPOINT__
      }, cleanUpObject(trackingConfig.facebook || {})),
      twitter: {
        app_id: __TWITTER_APP_ID__
      },
      crm: {
        api_url: __CRM_API_URL__
      }
    }), customConfig);
  }

  static ensureTrackerInstance(name: string, mapping: (s: SchemaDSLInterface) => any,
                               options: Options, config: any) {
    if (trackers[name])
      return trackers[name];

    const schema = new Schema();
    mapping(SchemaDSL(schema));

    if (trackers[name])
      return trackers[name];

    const tracker = new Tracker(name, schema, options, config);
    trackers[name] = tracker;

    return tracker;
  }

  getAdapters(names?: Array<string>): Array<Adapter> | undefined {
    if (!names || names.length === 0)
      return undefined;

    const adapters: Array<Adapter> = [];

    for (const adapter of this.schema.adapters) {
      if (names.includes(adapter.getStream()))
        adapters.push(adapter);
    }

    return adapters;
  }

  setCustomerUserId(userId: string) {
    const { schema } = this;

    this.userController.setExternalId(userId);

    for (const adapter of schema.adapters)
      adapter.setCustomerUserId(userId);
  }

  subscribe(context: object, adapters?: Array<string>) {  // TODO: context and params must be specified
    return this.track(EventType.START_TRIAL, context, this.getAdapters(adapters));
  }

  pay(context: object, adapters?: Array<string>) { // TODO: context and params must be specified
    return this.track(EventType.PURCHASE, context, this.getAdapters(adapters));
  }

  customEvent(eventName: string, context?: object, adapters?: Array<string>) {
    return this.track(EventType.CUSTOM_EVENT, extend(context || {}, { eventName }), this.getAdapters(adapters));
  }

  async trackAsync(eventType: EventType, payload: {[key: string]: any}, adapters?: Array<Adapter>) {
    const resolvers = [];
    const eventId = uuid();
    const targetAdapters = adapters && adapters.length ? adapters : this.schema.adapters;
    const web_uuid = this.getFingerPrintId();

    if (checkNotEqlId(extend(payload || {}, { external_id: this.external_id }))) {
      const outdatedId = this.external_id;
      this.external_id = payload.customerUserId;

      this.track(EventType.CUSTOM_EVENT, extend(payload || {}, {
        eventName: 'reattribution',
        subtype: 'userIdChanged',
        external_id: outdatedId
      }), targetAdapters);
    }

    if (this.external_id && !payload.customerUserId)
      payload.customerUserId = this.external_id;

    for (const adapter of targetAdapters) {
      if (!adapter.enabled)
        continue;

      const events = this.schema.getEvents(adapter);

      if (!events || !events[eventType])
        continue;

      for (const { eventName, builder } of events[eventType]) {
        const ctx = adapter.context(payload);

        resolvers.push(adapter.add({
          name: eventName.call(ctx, payload),
          value: extend(builder.call(ctx, payload), {
            event_id: eventId,
            web_uuid
          }),
          context: adapter.context(payload),
        }));
      }
    }

    await Promise.all(resolvers); // TODO: add error handlers for adapter invokation

    return { sent: resolvers.length };
  }

  track(eventType: EventType, context: object, adapters?: Array<Adapter>): EventEmitter {
    const em = new EventEmitter();
    const promise = this.trackAsync(eventType, context, adapters);
    try {
      promise
        .then(data => em.emit('success', data, context))
        .catch(error => em.emit('error', error, context));
    } catch (error) {
      em.emit('error', error, context);
    }

    return em;
  }

  getFingerPrintId() {
    let visitorId;

    const adapters = this.getAdapters([AdjustAdapter.getStream()])
    if (adapters && adapters.length > 0) {
      if (adapters[0] instanceof AdjustAdapter)
        visitorId = adapters[0].getFingerPrintId();
    }

    return visitorId ? visitorId : this.userController.getVisitorId();
  }

  async getHeaders() {
    const headers: { [index: string]: string } = {};

    const fingerPrintId = this.getFingerPrintId();
    if (fingerPrintId)
      headers['x-fingerprint-id'] = fingerPrintId;

    for (const adapter of this.schema.adapters) {
      if (!adapter.getVisitorId)
        continue;

      const result = adapter.getVisitorId();
      if (!result)
        continue;

      const visitorId = typeof result === 'object' && typeof result?.then === 'function' ? await result : result;

      if (!visitorId || typeof visitorId !== 'string')
        continue;

      const trackerNumber = adapter.getTrackerNumber();
      if (trackerNumber)
        headers[`x-tracker-id-${trackerNumber}`] = visitorId;
    }

    return headers;
  }

  config() {
    return this.configController.getRemoteConfig();
  }

  async syncAndGetRemoteConfig() {
    return this.configController.syncAndGetRemoteConfig();
  }

  getConfigValue(key: string, defaultValue: any) {
    const cfg = this.config();

    return !isNone(cfg[key]) ? cfg[key] : defaultValue;
  }

  getConfigStringValue(key: string, defaultValue = '') {
    try {
      const value = this.getConfigValue(key, defaultValue);

      return String(value || defaultValue);
    } catch (e) {
      log.error(e);
    }

    return defaultValue;
  }

  getConfigIntegerValue(key: string) {
    const value = this.getConfigValue(key, 0);

    if (isNone(value) || typeof value !== 'string')
      return 0;

    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? 0 : (parsed || 0);
  }

  getConfigFloatValue(key: string) {
    const value = this.getConfigValue(key, 0);

    if (isNone(value) || typeof value !== 'string')
      return 0;

    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : (parsed || 0);
  }

  getConfigBooleanValue(key: string) {
    const value = this.getConfigValue(key, 0);

    if (!value)
      return false;

    switch (value.constructor.name) {
      case 'Boolean':
        return value;
      case 'String':
        return ['1', 'true', 'yes'].includes(value);
      case 'Number':
        return value > 0;
      default:
        return false;
    }
  }

  async join(experimentId: string, optionId: string, experimentLayer: string) {
    await this.configController.sync(experimentId, optionId, experimentLayer);
    await this.processExperiments();
    return this.config();
  }

  async leave(experimentType?: string) {
    await this.configController.markAsLeaved(experimentType);
    return this.config();
  }

  async setCustomConfig(value: { [key: string]: any }) {
    return this.configController.setCustomConfig(value);
  }

  async resetCustomConfig() {
    return this.configController.setCustomConfig({});
  }

  /**
   * Leave all experiments and re-join with default rules
   *
   * @returns {ConfigController.config}
   * */
  async resetExperiments() {
    await this.configController.resetExperiments();
    await this.processExperiments();
    return this.configController.getRemoteConfig();
  }

  private async processExperiments(options: any = {}) {
    await this.configController.syncAndGetRemoteConfig();
    const activeExperiments = this.configController.getActiveExperiments();

    for (const type in activeExperiments) {
      const ex = activeExperiments[type];
      if (ex.joined)
        continue;

      const context: Context = {
        experiment_active: 'true',
        firebase_option_id: ex.optionId,
        firebase_experiment_id: ex.experimentId,
        loggedIn: options.loggedIn,
        paid: options.paid,
        type: ex.type
      }

      await Promise.all([
        this.customEvent('firebase_experiment_id', context, [AdjustAdapter.getStream()]),
        this.customEvent(`${ex.experimentId}__${ex.optionId}`, context, [YandexMetrikaAdapter.getStream()])
      ])

      this.configController.markAsJoined(ex.type);
    }
  }

  private fire(eventName: string, ...args: any[]) {
    this.firedEvents[eventName] = args;
    this.emit(eventName, ...args);
  }

  listen(eventName: string, handler: (...args: any[]) => void) {
    if (this.firedEvents[eventName])
      handler.apply(this, this.firedEvents[eventName]);
    else
      this.once(eventName, handler);
  }
}
