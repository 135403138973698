/* ============
 * Actions for the notification module
 * ============
 *
 * The actions that are available on the
 * notification module.
 */

import {
  PUSH,

  STOP_PULLING,
  START_PULLING,

  FILL_ACTIVE_QUEUE,
  CLEAR_ACTIVE_QUEUE
} from './mutation-types.js'

export default {
  push,
  pull
}

function push ({ dispatch, commit, state }, message) {
  commit(PUSH, message)

  dispatch('pull', { commit, state })
}

function pull ({ commit, state }, options = {}) {
  if (state.pulling && !options.force) { return }
  commit(START_PULLING)

  commit(CLEAR_ACTIVE_QUEUE)
  commit(FILL_ACTIVE_QUEUE)

  const { waitingQueue, activeQueue } = state

  if (waitingQueue.length || activeQueue.length) { setTimeout(() => pull({ commit, state }, { force: true }), 2000) } else { commit(STOP_PULLING) }
}
