<script>
// eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiInput'
})
</script>

<script setup>
/* eslint-disable */
import { computed, defineEmits, defineProps, ref } from 'vue'
import IconEye from '../SVGIcons/IconEye.vue'
/* eslint-ebable */

const emit = defineEmits(['update:inputValue', 'focusOut'])
const props = defineProps({
  inputType: {
    type: String,
    default: 'text'
  },
  placeHolder: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  inputValue: {
    type: String,
    default: ''
  },
  fontSize: {
    type: String,
    default: '18px'
  },
  isValid: {
    type: Boolean,
    default: true
  }
})

const input = ref('')
const isFocusOutDone = ref(false)
const isReadyForCheck = computed(() => {
  return inputValue.value && isFocusOutDone.value
})
const validationPassed = computed(() => {
  return !isReadyForCheck.value || props.isValid
})

const showPassword = ref(false)

function onFocusOut () {
  isFocusOutDone.value = !!inputValue.value
  emit('focusOut')
}

const inputType = computed(() => {
  return showPassword.value ? 'text' : props.inputType
})

function toggleInputType () {
  showPassword.value = !showPassword.value
}

const inputValue = computed({
  get () {
    return props.inputValue
  },
  set (value) {
    emit('update:inputValue', value)
  }
})

const cssVars = computed(() => {
  return {
    '--font-size': props.fontSize
  }
})
</script>

<template>
  <div class="ui-input"
       @focusout="onFocusOut"
       tabindex="-1"
       :class="{ 'not-valid': !validationPassed}" :style="cssVars">
    <div class="ui-input__notice" v-if="inputValue">{{ $t(props.placeHolder) }}</div>
    <input @focusout="onFocusOut"
           class="ui-input__field"
           :type="inputType"
           v-model="inputValue" :placeholder="$t(props.placeHolder)">

    <div class="ui-input__icon"
         v-if="props.inputType === 'password'"
         @click="toggleInputType">
      <UiIcon
        :icon-name="'show password'"
        :icon-color="showPassword ? 'currentColor' : '#D5DBE3'"
        width="24px"
        height="24px"
      >
        <IconEye />
      </UiIcon>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.ui-input {
  position relative

  &__field {
    min-height 52px
    border 1px solid web-grey-300
    border-radius 8px
    color web-grey-700

    padding 19px 16px 6px
    font-size var(--font-size);
    line-height 70%
    vertical-align bottom

    &::placeholder {
      color web-grey-400
    }

    &:placeholder-shown {
      padding 13px 16px 12px
    }

    &:focus {
      border 1px solid web-blue-500
      outline web-blue-500 solid 1px !important
    }

    &[type="password"] {
      @font-face {
        font-family: Circe;
        src: url('@/assets/fonts/fontello/fontello.eot');
        src: url('@/assets/fonts/fontello/fontello.eot') format('embedded-opentype'),
          url('@/assets/fonts/fontello/fontello.woff2') format('woff2'),
          url('@/assets/fonts/fontello/fontello.woff') format('woff'),
          url('@/assets/fonts/fontello/fontello.ttf') format('truetype'),
          url('@/assets/fonts/fontello/fontello.svg') format('svg');
        font-weight: normal;
        font-style: normal;
      }

      font-size 8px
      letter-spacing 0.2em

      &:placeholder-shown {
        font-size var(--font-size);
        letter-spacing 0em
      }
    }
  }

  &__notice {
    position absolute
    left 16px
    top 6px
    color web-blue-500
    font-size 12px
    line-height 16px
  }

  &.not-valid {
    .ui-input__field {
      border-color web-red-500
      color web-red-500
      outline none !important
    }
  }

  &__icon {
    cursor pointer
    position absolute
    right 16px
    top 14px
    width 24px
    height 24px
  }
}

.rtl {
  .ui-input {
    &__icon {
      right auto
      left 16px
    }

    &__notice {
      right 16px
      left auto
    }
  }
}
</style>
