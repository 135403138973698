/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */
import { G } from '@2l/utils'

import AccountTransformer from '@/transformers/AccountTransformer'

import { i18n } from '@/plugins/vue-i18n'
import MeProxy from '../../../proxies/MeProxy.js'
import AuthProxy from '../../../proxies/AuthProxy.js'
import AccountProxy from '../../../proxies/AccountProxy.js'
import SubscriptionProxy from '../../../proxies/SubscriptionProxy.js'

import * as fields from './fields.js'
import * as types from './mutation-types.js'

import mappingCurrency from '@/mixins/mapping-currency'
import { instance } from '@/plugins/axios.js'
import SupportProxy from '../../../proxies/SupportProxy'

const { currenciesList } = mappingCurrency.data()

const getUser = G('result.user')

const authProxy = new AuthProxy()
const accountProxy = new AccountProxy({ _fields: fields.user })
const subscriptionProxy = new SubscriptionProxy()
const supportProxy = new SupportProxy()

// Ony for local development
export async function signIn (data, { login, password }) {
  const response = await authProxy.login({ login, password })

  if (!response.result.sessionId) { return }

  localStorage.setItem('developmentId', response.result.sessionId)

  window.location.href = 'http://localhost:8080'
}

export async function find ({ commit }) {
  const response = await new MeProxy({ _fields: fields.user }).all()
  const user = getUser(response)

  await commit(types.FIND, AccountTransformer.fetch(user))
}

export async function logout () {
  await authProxy.logout()
}

export async function updateLang ({ dispatch, getters, commit }, { lang }) {
  const currentLanguage = lang || getters.getCurrentLanguage

  await accountProxy.setLang({ lang: currentLanguage })

  commit(types.UPDATE_LANG, currentLanguage)

  await dispatch('find')
}

export async function updateActiveProfile ({ commit, rootGetters }, selectedProfile) {
  const lang = rootGetters['language/getDefaultLang']
  let currentProfile = lang === 'en' ? 'es' : 'en'
  if (selectedProfile) { currentProfile = selectedProfile }
  const data = {
    lang,
    activeProfile: currentProfile
  }

  await accountProxy.setActiveProfile({ data })

  commit(types.UPDATE_PROFILE, currentProfile)
}

export function detectLang ({ commit }) {
  commit(types.UPDATE_LANG)
}

export function setLang ({ commit }, lang) {
  commit(types.SET_LANG, lang)

  i18n.locale = lang
}

export function setMaterialAccessMode ({ commit }, mode) {
  commit(types.SET_MATERIAL_ACCESS_MODE, mode)

  this._vm.router.push({ name: 'home.index' })
}

export function restoreMaterialAccessMode ({ commit }) {
  commit(types.RESTORE_MATERIAL_ACCESS_MODE)
}

export async function cancelSubscription (context, bill) {
  await subscriptionProxy.cancel(bill)
}

export async function unsubscribe ({ commit, dispatch }, bill) {
  try {
    await dispatch('general/startLoading', {}, { root: true })
    await subscriptionProxy.unsubscribe(bill)

    commit(types.UNSUBSCRIBE_SUCCESS)

    await dispatch('account/find', {}, { root: true })
    await dispatch('general/stopLoading', {}, { root: true })
    document.cookie = '2chCtrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  } catch (e) {
    await dispatch('general/stopLoading', {}, { root: true })
  }
}

export async function updateSettings ({ commit }, settings) {
  const newSettings = settings.settings
  const {
    result: userSettings
  } = await accountProxy.updateSettings(newSettings)

  commit(types.UPDATE_SETTINGS, userSettings)
}

export async function sendUserMessage (context, { email, message }) {
  const options = {
    procedure: 'send',
    action: 'success'
  }

  const isAuth = context.rootGetters['account/isAuthenticated']

  if (isAuth) {
    await accountProxy.sendMessage({ email, message })
  } else {
    await supportProxy.sendMessage({ email, message })
  }

  await context.dispatch('modal/open', 'info', { root: true })
  await context.dispatch('modal/setOptions', options, { root: true })
}

export async function detectLocation ({ commit }) {
  const forcedLocation = (new URL(window.location.href).searchParams.get('forcedLocation') || '').toUpperCase()
  const location = document.getElementById('geo').getAttribute('data-country')

  if (currenciesList[forcedLocation]) {
    // Needed for manual testing and autotests
    instance.defaults.headers.common['X-Accept-Currency'] = currenciesList[forcedLocation].currency
    return commit(types.SET_USER_LOCATION, forcedLocation)
  }

  commit(types.SET_USER_LOCATION, location)
}

export default {
  find,
  signIn,
  logout,
  setLang,
  updateLang,
  updateActiveProfile,
  detectLang,
  updateSettings,
  setMaterialAccessMode,
  restoreMaterialAccessMode,
  cancelSubscription,
  unsubscribe,
  sendUserMessage,
  detectLocation
}
