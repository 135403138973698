/* ============
 * State of the materials module
 * ============
 *
 * The initial state of the materials module.
 */

export default {
  learningMaterials: [],
  materialIds: {}
}
