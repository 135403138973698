import { pick, asArray, ensureFunction, identity } from '@2l/utils';

import Schema from './schema';
import { EventType } from '../events';
import {
  AdapterConstructor,

  YandexAdapter,
  AdjustAdapter,
  TwitterAdapter,
  FacebookAdapter,
  OceanengineAdapter,
  GoogleAnalyticsAdapter,
  CRMAdapter
} from '../adapters';

interface AdapterDSLInterface {
  extend: (fn: any) => any;
  event: (event: EventType, target: string | string[] | Function, fn?: any) => any;
}

interface EventsHash {
  [index: string]: EventType;
}

interface SchemaDSLInterface {
  YandexAdapter: AdapterConstructor;
  AdjustAdapter: AdapterConstructor;
  TwitterAdapter: AdapterConstructor;
  FacebookAdapter: AdapterConstructor;
  OceanengineAdapter: AdapterConstructor;
  GoogleAnalyticsAdapter: AdapterConstructor;
  CRMAdapter: AdapterConstructor,

  events: EventsHash;
  adapter: (adp: AdapterConstructor, fn: (a: AdapterDSLInterface) => void) => void;
}

function SchemaDSL(schema: Schema): SchemaDSLInterface {
  return {
    YandexAdapter,
    AdjustAdapter,
    TwitterAdapter,
    FacebookAdapter,
    OceanengineAdapter,
    GoogleAnalyticsAdapter,
    CRMAdapter,

    events: {
      PURCHASE: EventType.PURCHASE,
      START_TRIAL: EventType.START_TRIAL,
      CUSTOM_EVENT: EventType.CUSTOM_EVENT
    },

    adapter
  };

  function adapter(adp: AdapterConstructor, fn: (a: AdapterDSLInterface) => void): void {
    const dsl = { extend, event };
    fn(dsl);

    function extend(extra: any) {
      fn = ensureFunction(extra, pick);

      schema.setExtraContext(adp, fn);

      return dsl;
    }

    function event(event: EventType, events: string | string[] | Function, params?: any) {
      const fn = ensureFunction(params || identity(), pick);
      const targetEvents = asArray(events).map(v => ensureFunction(v, identity))

      schema.addEvent(adp, event, targetEvents, fn)
    }
  }
}

export { SchemaDSLInterface, SchemaDSL };
