import log from 'loglevel';

import { EventEmitter } from 'tsee';
import { omit, isNone, identity, orEmptyString } from '@2l/utils';
import { Options, Stream, TrackerNumber, Event } from './interfaces';

const MAX_WAIT_COUNTER_LOAD = 5000;
const MIN_WAIT_COUNTER_LOAD = 1500;
const excludeExtraFields = omit('eventName');

abstract class Adapter extends EventEmitter {
  enabled = true;
  queue: Event[];
  client: any;
  context: (context?: any) => any;
  started: number;
  external_id?: string | null;
  environment?: string;

  public static stream: Stream;
  public static trackerNumber?: TrackerNumber;

  abstract _init(): any;
  abstract _invoke(event: Event): any;
  abstract setCustomerUserId(userId: string): void;

  static getStream(): Stream {
    if (this.stream)
      return this.stream;

    return this.name.toUpperCase().replace('ADAPTER', '') as Stream;
  }

  static getTrackerNumber() {
    if (this.trackerNumber)
      return this.trackerNumber;
  }

  public constructor(options?: Options) {
    super();
    this.queue = [];
    this.context = options?.context || identity();
    this.client = null;
    this.started = Date.now();
    this.external_id = options?.external_id;
  }

  getStream(): Stream {
    const konstructor = this.constructor as AdapterConstructor;
    return konstructor.getStream();
  }

  getTrackerNumber(): TrackerNumber {
    const konstructor = this.constructor as AdapterConstructor;
    return konstructor.getTrackerNumber();
  }

  start() {
    if (!this.enabled)
      return;

    this.init();

    const interval = setInterval(() => {
      if ((Date.now() - this.started) > MAX_WAIT_COUNTER_LOAD)
        clearInterval(interval);

      this.init();

      if (!this.isReady())
        return;

      this.emit('ready', this);
      this.drain().catch(console.error);
      clearInterval(interval);
    }, 100)
  }

  init() {
    if (!this.enabled)
      return;

    this._init();

    if (this.isReady())
      log.info('[%s] adapter is ready', this.getStream());
  }

  async invoke(event: Event) {
    if (!this.enabled)
      return;

    log.info('[%s] invoke event %s',
      this.getStream(),
      event.name,
      orEmptyString(event.value.subtype)
    );

    return this._invoke(event);
  }

  async add(event: Event) {
    if (!event || !this.enabled)
      return;

    if (this.isReady())
      return this.invoke(event);

    log.debug('[%s] added event %s to queue',
      this.getStream(), event.name);

    this.queue.push(event);
    await this.drain();

    return false;
  }

  async drain() {
    if (!this.isReady())
      return;

    const unprocessed: Event[] = [];

    while (this.queue.length) {
      const event = this.queue.shift();

      if (!event)
        break;

      try {
        log.info('[%s] start invocation event %s',
          this.getStream(), event.name);
        await this.invoke(event);
      } catch (error) {
        // eslint-disable-next-line
        log.error(error);
        unprocessed.unshift(event);
      }
    }

    this.queue.unshift(...unprocessed);
  }

  cleanup(ctx: {[index:string]: any}): {[index:string]: any} {
    ctx = excludeExtraFields(ctx);
    const obj:{[index:string]: any} = {};

    for (const k in ctx) {
      const value = ctx[k];

      if (isNone(value) || typeof value === 'symbol')
        continue;


      obj[k] = value;
    }

    return obj;
  }

  isReady(): boolean {
    return this.enabled && !!this.client && (Date.now() - this.started) > MIN_WAIT_COUNTER_LOAD;
  }

  /**
   * Returns a visitor identifier that can present a target analytics system
   *
   * @return Promise<string > | string | undefined
   * */
  getVisitorId?(): Promise<string> | string | undefined {
    return '';
  }

  getFingerPrintId?(): string | undefined {
    return;
  }

  async hash(message: string | undefined) {
    if (!message)
      return message;

    // This feature is available only in secure contexts (HTTPS)
    // @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    if (!crypto.subtle)
      return message;

    // eslint-disable-next-line no-undef
    const msgUint8: BufferSource = new TextEncoder()
      .encode(message);
    const hashBuffer: ArrayBuffer = await crypto.subtle
      .digest('SHA-256', msgUint8);

    return Array.from(new Uint8Array(hashBuffer)).map((b) => {
      return b.toString(16).padStart(2, '0');
    }).join('');
  }
}

interface AdapterConstructor {
  new (options?: Options): Adapter;
  getStream(): Stream;
  getTrackerNumber(): TrackerNumber;
}

export { AdapterConstructor, Adapter };
