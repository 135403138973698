import { EventType } from '../../events';

const mapping:{[index:string]: string} = {
  [EventType.START_TRIAL]: 'rtbnnv', // 'ActivatedTrialSubscription'
  [EventType.PURCHASE]: 'gwdhqc', // 'PurchasedSubscription'

  'Activated': 'yv7hyz',
  'ActivatedMature': 'p10e9s',
  'ActivatedTrialSubscription': 'rtbnnv',
  'ActivatedTrialSubscription_Mature': 'k3kna1',
  'AppInstalled': 'iftm6k',
  'PausedSubscription': 'om6r3r',
  'PurchasedSubscription': 'gwdhqc',
  'RefundSubscription': 'ucv88o',
  'SubscriptionNotProlonged': 'g6xzby',
  'SubscriptionTrialCancelled': '66z2d8',
  'Subscription_Activated': 'f61tqd',
  'activated_trial_subscription': '8f5v11',
  'add_words': 'u226kz',
  'ads': '2um1sd',
  'adult_content_popup': 'sbsxpw',
  'ai_dialogues_menu': 'sk76oa',
  'ai_dialogue': '7u1zur',
  'api': 'gw5wcw',
  'api_error': '3pzl2g',
  'app_installed': 'kbj3dg',
  'app_review_popup': '2midwq',
  'application': '3lb15q',
  'audio_book': '6uw2yb',
  'avatar': 'swlvyk',
  'banner': '2hp06r',
  'book_impression': 'ftn7v2',
  'book_reader': 'vh2hoc',
  'book_reader_setup': '5zcxx4',
  'book_reader_word': '3t5aje',
  'bookshelf_details': 'sywnuf',
  'catalog': 'ftl0va',
  'catalogs_to_roadmap_popup': '3yu2kn',
  'challenges': 'tzckga',
  'chat': 'yupuif',
  'chat_results': 'wucbhx',
  'chats': 'tpokt6',
  'clip_player': '42yrzg',
  'collection_details': 'tux3y2',
  'content_age_rating': 'piuzuo',
  'context_translation': 'b8b7il',
  'conversion': 'um6owg',
  'course_view': 'wxeemg',
  'courses': 'edv2zp',
  'courses_back_tapped': '2164ua',
  'courses_course_removed': '3ige5i',
  'courses_view': '8pk3ac',
  'courses_visited': 'm9cilt',
  'dashboard': 'lhsfjb',
  'deep_link': '3wb2xs',
  'details': 'nh7hne',
  'difficulty_selector_popup': '39r81r',
  'discount_popup': '6gimpa',
  'download_via_cellular_off': 'bv9rxf',
  'download_via_cellular_on': 'dq8fdk',
  'downloads': 'nydqgr',
  'duels_challenge': 'amtq1c',
  'duels_results': 'iwfdi6',
  'duels_search': 'hvx09j',
  'duels_start': 'fkpvm3',
  'duels_state': 'e486hh',
  'error_metric': 'bo6z23',
  'error_pop_up': 'ylaj4x',
  'error_report': 'f7halp',
  'ewa_kids_ad': 'rcapf8',
  'exercise': '1z1nxu',
  'explain_exercise': 'rcya5f',
  'fake_section': 'srq1go',
  'feedback_notification': 'f3skt9',
  'firebase_experiment_id': 'ccn48l',
  'games': 'k8jara',
  'immersive_chat': 'm6d788',
  'intercuts': 'p4csaw',
  'landing': 'n6w2w0',
  'language_level': 'g25tjo',
  'language_level_test_results': 'b3on9j',
  'language_level_test_self_rating': '37mev5',
  'language_level_test_start': 'la6rtl',
  'launch_screen': 'hstgw0',
  'learn_words': 'i2eeyd',
  'learning_back_tapped': '1iwn7x',
  'learning_language': 'n4hxxe',
  'learning_set_count': 'u0ie3h',
  'learning_visited': 'ul0q0o',
  'leave_email': 'l8l6sm',
  'lesson_results': '4lyg0m',
  'lesson_view': 'c4p2cz',
  'level_system_pop_up': 's15k6k',
  'library': 'up1jq7',
  'library_back_tapped': '8pfehg',
  'library_book_removed': 'xpi95o',
  'library_visited': 'yex0tx',
  'memento_list': 'x9b8nh',
  'memento_match': '8ti6sg',
  'memento_results': 'hmryuu',
  'memento_rules': 'n5wrl6',
  'memento_start': '6wxs07',
  'mother_tongue': 'khhos4',
  'notification': 'rikqk0',
  'notifications': 'ycozfq',
  'notifications_authorization': '30kal3',
  'onboarding': 'g6vx2q',
  'onboarding_checkout': 's7uju4',
  'onboarding_infographics': 'k5b8zd',
  'onboarding_subscription_promo': 'g3tpjk',
  'onboarding_checkout_promo': 'dq9nba',
  'onboarding_plan_creation': 'y9bt4d',
  'onboarding_awards': '9ysrfv',
  'onboarding_language_skills': 'wsgtg9',
  'onboarding_personal_plan': 'wfgitk',
  'onboarding_environment': 'dmz22v',
  'onboarding_abilities': 'cs3gta',
  'onboarding_topics': '1e10hu',
  'onboarding_frequency': 'of4sih',
  'onboarding_duration': 'mxusy9',
  'onboarding_name': 'ghp47g',
  'onboarding_active_profile': 'o8tm21',
  'onboarding_age': 'opcxzl',
  'onboarding_chat': 'dohe9h',
  'onboarding_daily_goal': '90bzub',
  'onboarding_email': '8femdi',
  'onboarding_idfa_pre_prompt': 'xg8ui0',
  'onboarding_interests': 'm6n6w5',
  'onboarding_kids': '3fkykx',
  'onboarding_language': 'djv7kb',
  'onboarding_learn_direction': 'm0hvnz',
  'onboarding_lng_level': 'nn96c7',
  'onboarding_lng_level_test': 'cmum8w',
  'onboarding_motivation': 'qwx872',
  'onboarding_notifications': '8vrvrr',
  'onboarding_program': 'urkmpt',
  'onboarding_program_results': 'm0e9kq',
  'onboarding_recommendations': '1o8ud6',
  'onboarding_registration': 'ueaz1i',
  'onboarding_saving': 'szwgs9',
  'onboarding_social_auth': 'lxc98n',
  'onboarding_start': 'tvhaz5',
  'onboarding_subscription': 'gwp6us',
  'onboarding_survey': 'c72w3l',
  'onboarding_welcome': '8y1g3n',
  'onboarding_courses_films': 'xfznfz',
  'onboarding_courses_lng_level': '82iykx',
  'open_lesson': 't9u1rv',
  'password': 'iz7f7z',
  'popup': 'on5jsk',
  'profile': 'w9r6ns',
  'profile_switcher': '7ryegj',
  'promo_action': 'in5w9o',
  'rating_main_screen': '3rvy6d',
  'rating_start_screen': 'k0z3ha',
  'reattribution': 'mcorbq',
  'referral_program': '8rcspt',
  'request_review': 'h0hb1a',
  'review_feedback': 'cd5944',
  'roadmap': 'k1x4b8',
  'roadmap_topic': 'y3858b',
  'select_course': 's7shx5',
  'select_daily_goal': 'ai4o5b',
  'settings': 'ivh28b',
  'share_app_popup': 'twtrot',
  'sign_in': 'vi85ou',
  'sign_in_by_email': 'zcdy0n',
  'sign_in_popup': 'g8adil',
  'sign_in_social_error': 'vspf9c',
  'skyeng': 'ca4dz5',
  'statistics_details': 'h55qd1',
  'streaks_popup': '7dqeth',
  'subscriptions': 'bzevox',
  'survey': '864eg0',
  'trialactivated': '92xh7t',
  'trialactivatedmature': 'm0ti3g',
  'trialcancelled': 'fpoyen',
  'upload_config': 'qi6xp2',
  'user_books': '4rzrhs',
  'user_books_state': 'do39zj',
  'user_disabled_notifications': '1yw1oq',
  'user_enabled_notifications': 'gyce2x',
  'user_feedback': 'iur1kz',
  'user_statistics': 'iyxo0l',
  'vocabulary_test': '69oee1',
  'watches': 'h3p413',
  'word_popup': '7wrf69',
  'wordcraft_game': 'kimefn',
  'wordcraft_result': 'g6eii7',
  'wordcraft_retry': 'jkvo4e',
  'wordcraft_state': 'ozguwb',
  'wordcraft_wrong_combination': '8gy0em',
  'words_added': '8bpif9',
  'words_choose_translation': 'wi5q14',
  'words_compose': '3buaxs',
  'words_compose_audition': 'xs6ggj',
  'words_find_pair': '1bf6ci',
  'words_learning': 'meu5at',
  'words_not_enough_popup': '6mb2oo',
  'words_ready_popup': '5d7p2g',
  'words_remember': 'l7j00a',
  'words_repeating': 'whl5bc'
};

export default mapping;
