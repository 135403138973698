/* ============
 * Mutation types for the courses module
 * ============
 *
 * The mutation types that are available
 * on the courses module.
 */

export const FIND = 'FIND'
export const SELECT = 'SELECT'
export const UNSELECT = 'UNSELECT'
export const SET_ROADMAP = 'SET_ROADMAP'
export const SET_ROW_COUNT = 'SET_ROW_COUNT'
export const SET_LAST_VISITED_LESSON_ID = 'SET_LAST_VISITED_LESSON_ID'

export default {
  FIND,
  SELECT,
  UNSELECT,
  SET_ROADMAP,
  SET_ROW_COUNT,
  SET_LAST_VISITED_LESSON_ID
}
