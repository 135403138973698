import BaseProxy from './Proxy.js'

class AccountProxy extends BaseProxy {
  /**
   * The constructor for the AccountProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v22/user', parameters)
  }

  /**
   * Method used to set new language for a current user
   *
   * @returns {Promise} The result in a promise.
   */
  setLang ({ lang }) {
    const activeProfile = (lang === 'en') ? 'es' : 'en'

    return this.submit('put',
      `${this.endpoint}/language`,
      { lang, activeProfile })
  }

  setActiveProfile ({ data }) {
    const { lang, activeProfile } = data

    return this.submit('put',
      `${this.endpoint}/language`,
      { lang, activeProfile })
  }

  updateSettings (data) {
    return this.submit('put', `${this.endpoint}/settings`, data)
  }

  /**
   * Method used to send message to suppport service
   *
   * @returns {Promise} The result in a promise.
   */
  sendMessage ({ email, message }) {
    const data = { email, message }

    return this.submit('post', `${this.endpoint}/support/requests`, data)
  }
}

export default AccountProxy
