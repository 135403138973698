import { EventType } from '../../events';

const mapping:{[index:string]: string} = {
  [EventType.START_TRIAL]: 'ses3ib', // 'ActivatedTrialSubscription'
  [EventType.PURCHASE]: 'mhw9kz', // 'PurchasedSubscription'

  'abtesting': '4q7xp8',
  'Activated': '7euppf',
  'ActivatedMature': 'nijtn0',
  'ActivatedTrialSubscription': 'ses3ib',
  'ActivatedTrialSubscription_Mature': 'msfjg4',
  'adult_gates': 'wvafdw',
  'alphabet_view': '7uuner',
  'api_analytics': 'z29lx6',
  'api_error': 'lvqw0a',
  'app_installed': 'i4akc7',
  'AppInstalled': 'fk5uvm',
  'application': 'kux1s2',
  'app_review_popup': 'nroqwu',
  'audio_book': 'hh7a9m',
  'avatar': 'vz9h9u',
  'balloons_exercise': '5g7wlh',
  'book_impression': 'p8nvy9',
  'book_reader': '5a28ye',
  'book_reader_setup': '8lv9ys',
  'book_reader_word': 'syunpy',
  'catalog': 'fqnsif',
  'cheer_up': '1hjio3',
  'choose_image_by_text': 'cu3myt',
  'clip_player': '7o1uqz',
  'compose_phrase_by_image': 'folwjn',
  'context_translation': 'awim8h',
  'conversion': 'gtg4ie',
  'course_view': 'xumeod',
  'deep_link': '4z2bd4',
  'DeferredSubscription': 'wyiyuh',
  'details': 'yb28dc',
  'DevActivated': '5qgo7j',
  'DevActivatedMature': 'fh6zwe',
  'DevDeferredSubscription': '3tebrm',
  'Devfeedback_notification': 'pxqjhp',
  'Devnotification': 'mznsr0',
  'DevPausedSubscription': '58r6pw',
  'DevPurchasedSubscription': 'qe7twl',
  'DevRefundSubscription': 'am2v8d',
  'DevSubscriptionNotProlonged': 't80857',
  'DevSubscriptionTrialCancelled': 'jqo9kf',
  'downloads': 'obkzb6',
  'draw_letter_exercise': '212ttb',
  'duels_challenge': 'u7g8dj',
  'duels_results': 'f7kwwv',
  'duels_search': 'ksfc11',
  'duels_state': '31v7yq',
  'error_report': 'we0ub6',
  'explanation_by_image': '4us9dz',
  'explanation_by_text': 's5tf1m',
  'explanation_by_video': '5m9rxz',
  'fake_section': 'xihaej',
  'feedback_notification': 'ocuhir',
  'firebase_experiment_id': 'killgs',
  'first_install': '89an21',
  'games': 'llqw1t',
  'genres': 'c61ol0',
  'hidden_object_game': 'h0fwl6',
  'hidden_object_list': 'z712t6',
  'hidden_object_results': 's8s1gd',
  'hidden_object_word_results': '4zcfnt',
  'insert_missed_letter_by_image': '9hmti5',
  'insert_missed_letter_by_video': 'stk24z',
  'landing': 's4c7ty',
  'launch_screen': 'yumi9y',
  'lesson_phase': '5x15lt',
  'lesson_results': 'wmusig',
  'lesson_view': '8wfe0b',
  'letter_explain_by_image': '4zwvi8',
  'letter_explain_by_video': 'x22bv5',
  'library': 'ag47um',
  'listen_and_choose_image': 'et3vcm',
  'memento_list': 'i2bvi2',
  'memento_match': '1df2ei',
  'memento_results': 'hgnpq6',
  'memento_rules': 'efhsm0',
  'memento_start': 's2tn5t',
  'mother_tongue': 'gat8ya',
  'notification': 'j2yz5g',
  'onboarding_adult_gates': '7mchui',
  'onboarding_age_range': 'xyuyu2',
  'onboarding_books': 'wj8h1p',
  'onboarding_certificates': '9ix29x',
  'onboarding_courses': 'j8jer5',
  'onboarding_email': '7llfss',
  'onboarding_language': 'ryn7bf',
  'onboarding_learning_direction': 'pf0qc4',
  'onboarding_lng_level': 'aw4a3s',
  'onboarding_motivation': '8dbsnh',
  'onboarding_reviews': 'truj4y',
  'onboarding_safety': 'xwcojz',
  'onboarding_saving': '8anq50',
  'onboarding_subscription': 'hveri7',
  'onboarding_welcome': 'qofkej',
  'PausedSubscription': '46emas',
  'PurchasedSubscription': 'mhw9kz',
  'RefundSubscription': 'peg856',
  're_install': 'qc8084',
  're_install_entry': 'vzkjvm',
  'review_feedback': 'xe8msy',
  'roadmap': 'xqj4v5',
  'select_course': 'ey3pni',
  'select_letters': '52o7uy',
  'select_letters_with_image': 'yhrltx',
  'select_letters_with_video_by_sentence': 'bg9rhs',
  'select_sentence': 'jspvrk',
  'select_sentence_with_video': 'uxnwn0',
  'settings': 'kuxd5e',
  'settings_edit_user_name': 'j403or',
  'speech_exercise': 'w9thu0',
  'Subscription_Activated': 'nk6qvy',
  'SubscriptionNotProlonged': 'y01eyw',
  'subscriptions': 'qv8ezd',
  'SubscriptionTrialCancelled': 'idrgp8',
  'swap_letters': '550m9m',
  'swap_letters_with_image': 'nehxga',
  'swap_letters_with_video': 'bp4961',
  'swipe_letters_exercise': 'vlt1o5',
  'swipe_letters_video_exercise': 'j4t00w',
  'swipe_letters_with_image': 'e32b2d',
  'swipe_letters_with_video_answer_by_sentence': 'xv0t9b',
  'user_feedback': 'evuaab',
  'wordcraft_game': 'xhm8tw',
  'wordcraft_result': 'm7pgsg',
  'wordcraft_retry': 'oe525n',
  'wordcraft_state': 'urzv0u',
  'wordcraft_wrong_combination': '22mfyb'
};

export default mapping;
