<template>
  <div class="ui-input">
    <label class="wrapper" :class="props.labelStyle" :for="value + label">
      <slot></slot>
      <div class="ui-input__text">
        {{ $t(props.label) }}
      </div>
      <input class="checkbox" type="checkbox"
             :id="value + label"
             :checked="isChecked"
             :value="value"
             :v-model="checked"
             ref="input"
             @change="updateInput" />
      <span class="checkmark"></span>
      <img :src="check" class="u-display-none" >
    </label>
  </div>
</template>

<script>
// eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiCheckbox'
})
</script>
<script setup>
// eslint-disable-next-line
import { defineProps, defineEmits, computed, ref } from 'vue'
// eslint-disable-next-line
import check from '@/assets/img/ai/check.svg'

const emit = defineEmits(['update:value', 'checked', 'unChecked'])

const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  checked: {
    type: Boolean,
    default: false
  },
  labelStyle: {
    type: String,
    default: 'web-typo-bold-5'
  }
})

const input = ref('')

function updateInput () {
  if (input.value.checked) { emit('checked') } else { emit('unChecked') }
}

const isChecked = computed({
  get () {
    return props.checked
  },
  set (value) {
    emit('update:checked', value)
  }
})
</script>

<style lang="stylus" scoped>
.wrapper {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  font-size: 16px;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: main-white;
  border: 1px solid #ccc;

  top: 50%;
  transform: translateY(-50%);

  background-color: rgba(0, 0, 0, 0);

  transition all 0.3s;
}

.wrapper:hover input ~ .checkmark {
  border-color web-blue-500;
}

.wrapper input:checked ~ .checkmark {
  position absolute
  background-image: url('@/assets/img/onboarding/table-flag.svg'), none;
  background-position: 50%, 50%;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.wrapper input:checked ~ .checkmark:after {
  display: block;
}

label {
  box-shadow none
}

.rtl {
  .wrapper {
    padding-right 27px
    padding-left 0
  }

  .checkmark {
    left auto
    right 0
  }
  .reverse-checkbox {
    padding-left 40px
    padding-right 0
    word-break break-word
    .checkmark {
      right auto
      left 0
    }
  }
}

.exit-screen__checkbox, .reverse-checkbox {
  &.rtl {
    padding-right 42px
  }
  padding-left 42px
  margin-bottom 0

  .ui-input__text {
    position relative
    top 1px
  }
  .checkmark {
    width 26px
    height 26px
    border-radius 5px
    border 2px solid web-grey-400
    background main-white
  }

  input:checked ~ .checkmark {
    position absolute
    background-image: url('@/assets/img/ai/check.svg'), none;
    background-position: 50%, 50%;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
  }
}

.reverse-checkbox {
  padding-right 40px
  padding-left 0

  display grid
  grid-template-columns auto auto
  grid-gap 16px
  justify-content start
  align-items center

  .checkmark {

    right 0
    left auto
  }
}
</style>
