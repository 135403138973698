/* ============
 * Getters for the general module
 * ============
 *
 * The getters that are available on the
 * general module.
 */

import { G, identity } from '@2l/utils'

const apiUrl = __API_URL__
const isProduction = __ENVIRONMENT__ === 'production'
const isDevelopment = __ENVIRONMENT__ === 'development'

if (!(apiUrl)) { throw new Error('API_URL must be defined') }

export default {
  isLoading: G('loading'),
  isProduction: identity(isProduction),
  isDevelopment: identity(isDevelopment),

  apiUrl: identity(apiUrl),
  isLocaleLoaded: G('isLocaleLoaded')
}
