/* ============
 * Mutations for the modal module
 * ============
 *
 * The mutations that are available on the
 * modal module.
 */

import {
  SHOW_MODAL,
  CLOSE_MODAL,
  SET_OPTIONS
} from './mutation-types.js'

export default {
  [SHOW_MODAL] (state, componentName) {
    state.visible = true
    state.component = componentName
  },
  [SET_OPTIONS] (state, options) {
    state.options = options
  },
  [CLOSE_MODAL] (state) {
    state.visible = false
  }
}
