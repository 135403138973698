<template>
  <svg width="100%" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="12" r="5" stroke="currentColor" stroke-width="2"/>
    <path d="M13 4C5 4 2 12 2 12C2 12 5 20 13 20C21 20 24 12 24 12C24 12 21 4 13 4Z" stroke="currentColor" stroke-width="2"/>
  </svg>
</template>

<script>
</script>

<style scoped>

</style>
