/* ============
 * Mutation types for the materials module
 * ============
 *
 * The mutation types that are available
 * on the materials module.
 */

export const SET_LEARNING_MATERIALS = 'SET_LEARNING_MATERIALS'
export const SET_MATERIAL_ID = 'SET_MATERIAL_ID'

export default {
  SET_LEARNING_MATERIALS,
  SET_MATERIAL_ID
}
