import BaseProxy from './Proxy.js'
import { uuidV4 } from '../lib/helpers'

class SupportProxy extends BaseProxy {
  constructor (parameters = {}) {
    super('api/v22/support', parameters)
  }

  sendMessage ({ email, message }) {
    const data = { email, message }
    const randomId = uuidV4()

    return this.submit('post', `${this.endpoint}/requests/${randomId}`, data)
  }
}

export default SupportProxy
