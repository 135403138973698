/* ============
 * Actions for the language module
 * ============
 *
 * The actions that are available on the
 * language module.
 */

import utils from '@2l/utils'

import { i18n } from '@/plugins/vue-i18n'

import Transformer from '@/transformers/LanguageTransformer'
import LanguageProxy from '@/proxies/LanguageProxy.js'
import PropertiesProxy from '@/proxies/PropertiesProxy.js'

import {
  ENSURE, SELECT, SET_VERSION, SUBSCRIBE, SET_SELECTED_CODE
} from './mutation-types.js'

const { G, firstOf, identity } = utils

const getMessages = firstOf(G('keys'), identity({}))

const languageProxy = new LanguageProxy()

export async function ensure ({ commit }) {
  const response = await languageProxy.all({
    includeSystem: true,
    selectableOnly: true
  })

  commit(ENSURE, Transformer.fetchCollection(response.result.rows))
}

export async function ensureLocales ({ dispatch }) {
  const response = PropertiesProxy.getCachedLocalization()

  const messages = getMessages(response)

  for (const key in messages) {
    const values = messages[key]
    i18n.setLocaleMessage(key, values)
  }

  await dispatch('general/setLocaleLoaded', {}, { root: true })
}

export function select ({ commit }, language) {
  commit(SELECT, language)
  commit(SET_SELECTED_CODE, language.code)
}

export function unSelect ({ commit }) {
  commit(SET_SELECTED_CODE, null)
}

export async function subscribe ({ commit }) {
  commit(SUBSCRIBE)
}

export function update ({ commit, rootState }, { settings, version }) {
  const { account } = rootState
  const { lang } = account

  i18n.setLocaleMessage(lang, settings)

  commit(SET_VERSION, version)
}

export default {
  ensure,
  select,
  unSelect,
  update,
  subscribe,
  ensureLocales
}
