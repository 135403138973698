/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  isAuthenticated: !!localStorage.getItem('id_token'),
  registrationMethod: null
}
