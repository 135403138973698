import BaseProxy from './Proxy.js'

class LanguageProxy extends BaseProxy {
  /**
   * The constructor for the LanguageProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/langs', parameters)
  }
}

export default LanguageProxy
