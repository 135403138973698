/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes'

export const router = createRouter({
  history: createWebHistory(__ROUTER_PREFIX__ || '/'),
  routes
})

export default {
  router
}
