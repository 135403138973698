import { openDB } from 'idb'

const indexDBName = 'ewa'
const indexDBVersion = 9

class DataBase {
  constructor () {
    this.stores = [
      'words',
      'heatmap',
      'replicas',
      'resources',
      'lessons'
    ]
  }

  async getDb () {
    if (this.db) { return this.db }

    const { stores } = this

    this.db = await openDB(indexDBName, indexDBVersion, {
      upgrade (db) {
        for (const store of stores) {
          if (!db.objectStoreNames.contains(store)) { db.createObjectStore(store, { autoIncrement: true }) }
        }
      }
    })

    return this.db
  }

  async set (storeName, key, data) {
    const db = await this.getDb()

    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)

    await store.put(data, key)
    await tx.done
    return data
  }

  async get (storeName, key) {
    const db = await this.getDb()

    const tx = db.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)

    const val = await store.get(key)
    await tx.done
    return val
  }

  async drop (storeName, key) {
    const db = await this.getDb()

    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)

    await store.delete(key)
    await tx.done
  }

  async cleanup (key) {
    const db = await this.getDb()

    const operations = []
    const { stores } = this

    for (const storeName of stores) {
      const transaction = db.transaction(storeName, 'readwrite')
      const store = transaction.objectStore(storeName)

      operations.push(store.delete(key))
    }

    await Promise.all(operations)
  }
}

export default new DataBase()
