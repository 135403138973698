import { v4 as uuid } from 'uuid';
import { isNone, extend } from '@2l/utils';
import { Adapter, Event } from '../adapter';
import { AdjustOptions } from './interfaces';

import log from 'loglevel';
import Adjust from '@adjustcom/adjust-web-sdk';
import GlobalParams = Adjust.GlobalParams;
import InitOptions = Adjust.InitOptions;

const MIN_DEDUPLICATION_LIMIT = 100;

import tokens_standard from './tokens.standard';
import tokens_kids from './tokens.kids';

const tokens = {
  'kids': tokens_kids,
  'standard': tokens_standard
};

export default class AdjustAdapter extends Adapter {
  static stream: Uppercase<string> = 'ADJUST';
  static trackerNumber = '8';

  appToken: string;
  edition: 'standard' | 'kids';
  environment: 'production' | 'sandbox';
  eventMapping: {[index:string]: string};
  external_id?: string;
  default_tracker?: string;
  customer_user_id?: string;
  event_deduplication_list_limit?: number;

  constructor(options?: AdjustOptions) {
    super(options);

    this.appToken = options?.adjust?.appToken ?? 'tl9w25jgsni8';
    this.environment = options?.adjust?.environment ?? 'sandbox';
    this.edition = options?.edition ?? 'standard';
    this.eventMapping = tokens[this.edition] || tokens.standard;

    this.external_id = options?.adjust?.externalDeviceId;
    this.default_tracker = options?.adjust?.defaultTracker;
    this.event_deduplication_list_limit = Math.max(options?.adjust?.eventDeduplicationListLimit || 10,
        MIN_DEDUPLICATION_LIMIT);
  }

  _init() {
    if (this.client)
      return;

    const { appToken, environment = 'sandbox' } = this;
    if (!appToken)
      return;

    const params: Array<GlobalParams> = [{'key': 'os', value: 'web'}]
    const initOptions = {
      appToken,
      environment,
    } as InitOptions;

    if (this.event_deduplication_list_limit)
      initOptions.eventDeduplicationListLimit = this.event_deduplication_list_limit;

    if (this.default_tracker)
      initOptions.defaultTracker = this.default_tracker;

    if (this.external_id)
      initOptions.externalDeviceId = this.external_id;

    Adjust.initSdk(initOptions);

    Adjust.addGlobalCallbackParameters(params);
    Adjust.addGlobalPartnerParameters(params);

    this.client = Adjust;
  }

  async _invoke(event: Event) {
    if (!this.client)
      throw new Error('Adjust client must be initialized before');

    const eventToken = this.eventMapping[event.name];
    if (!eventToken) {
      log.info('[%s] Event token can\'t be detected for given name: %s',
        AdjustAdapter.getStream(), event.name);
      return;
    }

    if (!event.context.event_id)
      event.context.event_id = uuid();

    const params = normalizeParameters(extend(event.context, event.value));

    await Adjust.trackEvent({
      eventToken,
      partnerParams: params,
      callbackParams: params,

      deduplicationId: event.context.event_id
    });
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (!Adjust)
      return;

    Adjust.addGlobalPartnerParameters([
      { key: 'customer_user_id', value: userId }
    ]);

    // TODO: restart Adjust SDK
  }

  getVisitorId(): string | undefined {
    const attrs = this.client.getAttribution();
    if (!attrs)
      return;

    return attrs.adid;
  }

  getFingerPrintId(): string | undefined {
    return this.client.getWebUUID();
  }
}

function normalizeParameters(ctx: {[index:string]: any}): Adjust.GlobalParams[] {
  const params: Adjust.GlobalParams[] = [{ key: 'os', value: 'web' }];

  for (const key in ctx) {
    if (key === 'eventName') // exclude meta field
      continue;

    const val = ctx[key];
    if (isNone(val))
      continue;

    params.push({
      key,
      value: val.toString()
    });
  }

  return params;
}
