/* ============
 * Mutations for the materials module
 * ============
 *
 * The mutations that are available on the
 * materials module.
 */
import {
  SET_LEARNING_MATERIALS, SET_MATERIAL_ID
} from './mutation-types.js'

export default {
  [SET_LEARNING_MATERIALS] (state, learningMaterials) {
    state.learningMaterials = learningMaterials.materials
    state.materialIds = learningMaterials.materialIds
  },
  [SET_MATERIAL_ID] (state, material) {
    const { materialId } = material

    state.materialIds[materialId] = material
  }
}
