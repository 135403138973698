import { G } from '@2l/utils'

const getToken = G('authResponse.accessToken')

class Facebook {
  async authenticate () {
    return new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          const token = getToken(response)

          if (!token) { reject(new Error('alert_something_went_wrong')) }

          resolve(token)
        },
        error => reject(error)
      )
    })
  }
}

export default Facebook
