import * as Sentry from '@sentry/browser';
import { v4 as uuid } from 'uuid';
import { Event, Adapter } from '../adapter';
import { compose, pick, omit } from '@2l/utils';
import {
  FacebookWindow,
  FacebookOptions,
  FacebookPixelParams
} from './interfaces';

import { fbc, fbp } from './helpers';

declare let window: FacebookWindow;

const defaultBaseURL = 'https://twsesi.appewa.com/tr';
const defaultPixelID = '1857389657752904';
const exposeCustomData = pick('event_id');

export default class FacebookAdapter extends Adapter {
  static stream: Uppercase<string> = 'FACEBOOK';

  pixel_id?: string;
  base_url?: string;

  constructor(options?: FacebookOptions) {
    super(options);

    this.enabled = options?.facebook?.enabled !== false

    if (!this.enabled)
      return;

    this.pixel_id = options?.facebook?.pixel_id ?? process.env.FACEBOOK_PIXEL_ID ?? defaultPixelID;
    this.base_url = options?.facebook?.base_url ?? defaultBaseURL;

    this._createPixel(options?.external_id).catch(Sentry.captureException);

    this.cleanup = compose(this.cleanup, omit('event_id')).bind(this);
  }

  _init() {
    if (this.client || !window.fbq || !this.enabled)
      return this.client;

    window.fbq('set', 'endpoint', this.base_url);

    // TODO: use kickstart to support advanced matching
    if (this.pixel_id)
      window.fbq('init', parseInt(this.pixel_id, 10));

    if (this.external_id)
      window.fbq('track', 'PageView', { external_id: this.external_id });

    this.client = window.fbq;

    return this.client;
  }

  async _invoke(event: Event) {
    if (!this.enabled)
      return;

    if (!this.client)
      throw new Error('Facebook client must be initialized before');

    switch (event.name) {
      case 'Purchase':
      case 'Subscribe':
      case 'StartTrial':
        return window.fbq('track', event.name, this.cleanup(event.value), exposeCustomData(event.value));
      default:
        return window.fbq('trackCustom', event.name, this.cleanup(event.value), exposeCustomData(event.value));
    }
  }

  // TODO: should initialize fbq to use advanced matching?
  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (window.fbq)
      window.fbq('track', 'PageView', { external_id: userId });
  }

  getVisitorId(): Promise<string> | string | undefined {
    return this.external_id || undefined;
  }

  async _createPixel(externalId?: string) {
    const location = encodeURIComponent(window.location.href);
    const eventId = uuid();

    const values = await Promise.all([
      this.hash(externalId),
      this.hash(eventId)
    ]);

    const params: FacebookPixelParams = {
      ev: 'ViewContent',
      rqm: 'GET',
      noscript: '1',
      id: this.pixel_id || defaultPixelID,
      dl: location,
      rl: location,
      ts: Date.now().toString(),
      'cd[external_id]': externalId,
      'id[external_id]': values[0]
    };

    params.fbc = fbc();
    params.fbp = fbp();

    if (eventId || values[1])
      params.eid = params.event_id = eventId || values[1];

    const img = document.createElement('img');
    const url = new URL(this.base_url || defaultBaseURL);
    const promise = new Promise(function (resolve, reject) {
      img.onload = resolve;
      img.onerror = reject;
    });

    // @ts-ignore
    url.search = new URLSearchParams(params).toString();
    document.body.appendChild(img);
    img.style.display = 'none';
    img.src = url.toString();

    await promise;
  }
}
