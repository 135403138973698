import BaseProxy from './Proxy.js'

class PropertiesProxy extends BaseProxy {
  /**
   * The constructor for the PropertiesProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/properties', parameters)
  }

  /**
   * Returns localization by user language
   *
   * @return {Object} All localized keys
   */
  getLocalization () {
    const resp = this.submit('get', `${this.endpoint}/all`)
    if (resp) { this.cached = resp }

    return this.cached
  }

  /**
   * Returns cached localization
   *
   * @return {Object}
   */
  static getCachedLocalization () {
    return this.cached
  }
}

export default PropertiesProxy
