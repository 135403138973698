import * as u from './util';
import Cookies from 'js-cookie';

import { URLSchemaDSL } from './interfaces';
import { AdjustAdapter } from '../adapters';

const ADJUST_CLICK_URL = __ADJUST_CLICK_URL__ ?? '';
const idsBySource = {
  google_ads: '15vh89vj',
  twitter: 'ux0hrlx',
  email: '6m6i49o',
  push: '1mdwuu4',
  oceanengine: 'zsecu0t', // OceanEngine_Social
}
export default function (s: URLSchemaDSL) {
  s.url(AdjustAdapter.getStream(), ADJUST_CLICK_URL, {
    deep_link: u.param('deep_link', 'ewa://'),

    fbclid: u.firstOfParam(['fbclid'], u.getLastPart(Cookies.get('_fbc'))),
    clickid: u.firstOfParam(['fbclid', 'mid', 'gclid'], u.getLastPart(Cookies.get('_fbc'))),

    // google ads
    gclid: u.param('gclid'),
    wbraid: u.param('wbraid'),
    gbraid: u.param('gbraid'),

    // label
    label: u.firstOfParam(['label', 'mid', 'messageId', 'message_id']),

    // ad
    creative_id: u.param('af_ad_id'),
    creative: u.firstOfParam(['af_ad', 'creative', 'creative_name']),
    // ad set
    adgroup_id: u.param('af_adset_id'),
    adgroup: u.firstOfParam(['af_adset', 'adgroup', 'adgroup_name']),
    // campaign
    campaign_id: u.firstOfParam(['af_c_id', 'cid']),
    campaign: u.firstOfParam(['c', 'campaign', 'campaign_name'])
  })
    .alias('KickStart')
    .tracker(idsBySource, '9gx57vk');

  s.url(AdjustAdapter.getStream(), 'https://app.adjust.com/noi4yb0',
    s.from(AdjustAdapter.getStream()))
    .alias('Web')
    .tracker(idsBySource, 'noi4yb0', '', 'Web');
}
