import log from 'loglevel'
import Cookies from 'js-cookie';

import { v4 as uuid } from 'uuid';
import { EventEmitter } from 'tsee';
import { Booking, Experiment } from './interfaces';

const API_VERSION = 'v18';

export default class UserController extends EventEmitter {
  private localStorageKeyVisitor = '_ewa_visitorId';
  private localStorageKeyExternalId = 'externalId';
  private localStorageKeyExperiment = 'experiment';

  external_id?: string | null;
  visitorId?: string | null;

  constructor(externalId?: string) {
    super();

    this.external_id = externalId || localStorage.getItem(this.localStorageKeyExternalId)
      || undefined;
    this.visitorId = this.storeVisitorId(this.extractVisitorId() ||
      Cookies.get('_fbp') || uuid());
  }

  async init(externalId?: string) {
    if (externalId)
      this.external_id = externalId;

    if (this.visitorId)
      return;

    try {
      this.visitorId = this.storeVisitorId(uuid());
    } catch (error) {
      log.error(error);
    }
  }

  getExternalId() {
    return this.external_id;
  }

  setExternalId(externalId: string): string {
    this.external_id = externalId;
    localStorage.setItem(this.localStorageKeyExternalId, externalId);

    return externalId;
  }

  ensureExternalId(): string | undefined {
    return this.getExternalId() || undefined;
  }

  getVisitorId() {
    return this.visitorId;
  }

  async book(options?: {
    adjustWebUUID?: string | undefined,
    adjustTrackerId?: string | undefined,
    force?: boolean
  }): Promise<string | undefined> {
    if (this.external_id && !options?.force) {
      log.info('[EWA.ANALYTICS] the identifier has been reused for a user: %s',
        this.external_id);
      return this.external_id;
    }

    const booking = this.createBaseBooking();

    if (options?.adjustWebUUID)
      booking.adjustWebUUID = options?.adjustWebUUID;
    if (options?.adjustTrackerId)
      booking.adjustTrackerId = options?.adjustTrackerId;

    this.external_id = await this.callReservationAPI(this.external_id ? 'PUT' : 'POST', booking);
    return this.external_id || undefined;
  }

  private createBaseBooking(): Booking {
    const externalId = this.ensureExternalId();
    const query = new URLSearchParams(window.location.search);
    const fbc = query.get('fbclid') || Cookies.get('_fbc');
    const fbp = Cookies.get('_fbp');

    const order = {
      externalId,
      experiment: this.getExperiment(),
      eventSourceUrl: document.location.href,

      facebookPixelId: query.get('fb_pixel_id') || undefined,
      facebookClickId: fbc,
      facebookBrowserId: fbp,

      twitterClickId: query.get('twclid') || undefined
    };

    if (query.get('utm_source') === 'google_ads')
      Object.assign(order, {
        googleGclid: query.get('gclid') || undefined,
        googleWbraid: query.get('wbraid') || undefined,
        googleGbraid: query.get('gbraid') || undefined,

        googleTrackerId: query.get('gclid') || undefined
      });

    if (query.get('utm_source') === 'oceanengine')
      Object.assign(order, {
        oceanengineCampaign: query.get('campaign') || undefined,
        oceanengineCampaignId: query.get('adid') || query.get('campaignId') || undefined,
        oceanengineAdGroup: query.get('adgroup') || undefined,
        oceanengineCreative: query.get('creativetype') || query.get('creative') || undefined,
        oceanengineCreativeId: query.get('creativeid') || query.get('creative_id') || undefined,
        oceanengineClickId: query.get('clickid') || undefined
      });

    return order;
  }

  private async callReservationAPI(method: 'POST' | 'PUT' = 'POST', booking: Booking) {
    const resp = await fetch(`${__API_URL__}/api/${API_VERSION}/users/reserve`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Platform-ID': '255c5893-e2a4-4860-83d8-c2e30433e9f6'
      },
      credentials: 'include',
      body: JSON.stringify(booking)
    });

    const { result } = await resp.json();

    log.info('[EWA.ANALYTICS] the identifier has been reserved for a user: %s',
      result?.booking?.externalId);

    return result?.booking?.externalId;
  }

  getExperiment(): Experiment {
    let storedExperiment;

    try {
      storedExperiment = JSON.parse(localStorage.getItem(this.localStorageKeyExperiment) || '');
    } catch {
      storedExperiment = null;
      // ignore potential error
    }

    return storedExperiment;
  }

  private extractVisitorId(): string | null {
    return localStorage.getItem(this.localStorageKeyVisitor);
  }

  private storeVisitorId(visitorId?: string) {
    if (!visitorId)
      return;

    localStorage.setItem(this.localStorageKeyVisitor, visitorId);
    return visitorId;
  }
}
