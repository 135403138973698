/* ============
 * Mutation types for the language module
 * ============
 *
 * The mutation types that are available
 * on the language module.
 */

export const ENSURE = 'ENSURE'
export const SELECT = 'SELECT'
export const SUBSCRIBE = 'SUBSCRIBE'
export const SET_VERSION = 'SET_VERSION'
export const SET_SELECTED_CODE = 'SET_SELECTED_CODE'

export default {
  ENSURE,
  SELECT,
  SUBSCRIBE,
  SET_VERSION,
  SET_SELECTED_CODE
}
