import { isNone } from '@2l/utils';

const mapping: { [index:string]: string } = {
  'ActivatedTrialSubscription': 'Commercial',
  'ActivatedTrialSubscription_Mature': 'Commercial'
};

const DEFAULT_CATEGORY = 'unspecified';

export function getCategory(eventName: string): string {
  if (isNone(eventName))
    return DEFAULT_CATEGORY;

  const category = mapping[eventName || ''];
  if (category)
    return category;

  return eventName.split('_').shift() || DEFAULT_CATEGORY;
}
