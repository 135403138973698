/* ============
 * Getters for the courses module
 * ============
 *
 * The getters that are available on the
 * courses module.
 */

import {
  G,
  not,
  firstOf,
  partial,
  compose,
  filter, get
} from '@2l/utils'

const notIsLocked = not(G('locked'))
const getVisibleLessons = compose(
  firstOf(G('active.lessons'), () => []),
  partial(filter, notIsLocked)
)

export default {
  getLessons,
  getVisibleLessons,
  isLoaded: G('loaded'),
  getSelected: G('selected'),
  getSelectableCourses: G('selectable'),
  getFirstSelectableCourseId: firstOf(
    G('active._id'),
    G('selectable.0.value')
  ),
  getCurrentProgress: G('active.progress'),
  getActiveLessons: G('active.lessons'),
  getLessonsLength: G('active.lessons.length'),
  getCourseId: G('active._id'),
  getCourses: G('rows'),
  getStarsEarned: G('active.starsEarned'),
  getTotal: G('total'),

  getLastVisitedLessonId: G('lastVisitedLessonId')
}

function getLessons (state) {
  const { active } = state
  const lessons = get('active.lessons', state)

  if (!active || !lessons) { return [] }

  return lessons
}
