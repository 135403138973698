/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import {
  G,
  compose,
  invoke,
  firstOf,
  all,
  when,
  W,
  identity
} from '@2l/utils'

import Google from '@/lib/social/google'
import Facebook from '@/lib/social/facebook'
import Apple from '@/lib/social/apple'
import * as types from './mutation-types.js'
import * as fields from './fields.js'
import AuthProxy from '../../../proxies/AuthProxy.js'

const getSessionId = G('result.sessionId')

const getStatusCode = compose(G('result.id'), invoke('toString'))
const getStatus = firstOf(
  all(
    when({ statusCode: W('404.101 403.106') }),
    identity('fail')
  ),
  identity('success')
)
const getMessage = G('result.message')

const proxy = new AuthProxy()
const google = new Google()

const facebook = new Facebook()

const apple = new Apple()

export default {
  check,
  login,
  logout,

  resetPassword,
  changePassword,
  restorePassword,

  register,
  registerInPlace,
  authenticate,
  extendAccount,
  sendTokenAnonymous,
  registerWithEmail
}

export async function check ({ commit, getters, dispatch }) {
  await commit(types.CHECK)
  const authorized = getters.isAuthenticated

  if (authorized) { await dispatch('account/find', {}, { root: true }) }

  return true
}

export async function register ({ commit, dispatch, rootGetters }, { login, password }) {
  await dispatch('general/startLoading', {}, { root: true })
  const lang = rootGetters['account/getCurrentLanguage']

  const response = await proxy.register({
    lang,
    login,
    password,

    fields: fields.auth
  })

  const sessionId = getSessionId(response)

  await dispatch('general/startLoading', {}, { root: true })

  await commit(types.REGISTER, { method: 'Email' })
  await commit(types.LOGIN, sessionId)

  await dispatch('modal/close', {}, { root: true })
  await dispatch('account/find', {}, { root: true })

  this._vm.router.push({ name: 'home.index' })
}

export async function registerInPlace ({ commit, dispatch }, { login, password, lang }) {
  await dispatch('general/startLoading', {}, { root: true })

  const response = await proxy.register({
    lang,
    login,
    password,

    fields: fields.auth
  })

  const sessionId = getSessionId(response)

  await commit(types.REGISTER, { method: 'Email' })
  await commit(types.LOGIN, sessionId)

  await dispatch('account/find', {}, { root: true })

  await dispatch('general/stopLoading', {}, { root: true })
}

export async function registerWithEmail ({ commit, dispatch }, data) {
  await dispatch('general/startLoading', {}, { root: true })
  const response = await proxy.emailRegister(data)

  const sessionId = getSessionId(response)

  await commit(types.REGISTER, { method: 'Email' })
  await commit(types.LOGIN, sessionId)

  await dispatch('account/find', {}, { root: true })

  await dispatch('general/stopLoading', {}, { root: true })
}

export async function extendAccount ({ getters, rootGetters }, email) {
  const hasEmail = rootGetters['account/getEmail']
  const lang = rootGetters['account/getCurrentLanguage']

  if (hasEmail || !getters.isAuthenticated) { return }

  const response = await proxy.register({
    email,
    password: email,

    lang,
    login: email
  })

  return response
}

export async function restorePassword (context, { email }) {
  const response = await proxy.restore(email)
  const statusCode = getStatusCode(response)
  const status = getStatus({ statusCode })
  const message = getMessage(response)

  const options = {
    procedure: 'restore',
    action: status,
    message,
    email
  }

  return options
}

export async function changePassword (context, { password }) {
  await proxy.change({
    newPassword: password
  })
}

export async function resetPassword (context, { password: newPassword, token }) {
  return proxy.reset({ newPassword, token })
}

// eslint-disable-next-line no-shadow
export async function login ({ commit, dispatch }, { login, password }) {
  await dispatch('general/startLoading', {}, { root: true })
  const response = await proxy.login({
    login,
    password,

    fields: fields.auth
  })

  const { sessionId } = response.result

  if (!sessionId) {
    await dispatch('general/stopLoading', {}, { root: true })
    return
  }

  commit(types.LOGIN, sessionId)

  await dispatch('account/find', {}, { root: true })
  await dispatch('general/stopLoading', {}, { root: true })
}

export async function authenticate ({ commit, dispatch, rootGetters }, provider) {
  const lang = rootGetters['account/getCurrentLanguage']
  const accessToken = await getTokenByProvider(provider)
  const response = await proxy.auth({ accessToken, network: provider, lang })

  const sessionId = getSessionId(response)
  await commit(types.LOGIN, sessionId)

  await dispatch('account/find', {}, { root: true })

  await commit(types.REGISTER, { method: provider })
}

async function getTokenByProvider (provider) {
  switch (provider) {
    case 'facebook':
      return facebook.authenticate()
    case 'google':
      return google.authenticate()
    case 'apple':
      return apple.authenticate()
    default:
      throw new Error(`Invalid provider: ${provider}`)
  }
}

export async function logout ({ commit }) {
  let res = null
  let exception = null

  try {
    res = await new AuthProxy().logout()
    document.cookie = '2chCtrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    localStorage.removeItem('externalId')
  } catch (err) {
    exception = err
  }

  if (exception && exception.error.id !== 401) { return }
  if (res && res.status && res.status.code > 204) { return }

  commit(types.LOGOUT)
}

// eslint-disable-next-line no-unused-vars
export async function sendTokenAnonymous ({ commit }, token) {
  await proxy.hello(token)
}
