/* ============
 * Mutation types for the courses module
 * ============
 *
 * The mutation types that are available
 * on the courses module.
 */

export const SELECT_NEXT_REPLICA = 'SELECT_NEXT_REPLICA'
export const CHECK_ENTERED_WORD = 'CHECK_ENTERED_WORD'
export const REMOVE_LAST_SYMBOL = 'REMOVE_LAST_SYMBOL'
export const SELECT_REPLICA = 'SELECT_REPLICA'
export const SELECT_ANSWER = 'SELECT_ANSWER'
export const SELECT_SYMBOL = 'SELECT_SYMBOL'
export const SELECT_PART = 'SELECT_PART'
export const PRESELECT_PART = 'PRESELECT_PART'
export const SELECT = 'SELECT'
export const PLAY = 'PLAY'
export const STOP = 'STOP'
export const SET_WORDS_EXERCISE = 'SET_WORDS_EXERCISE'
export const CHANGE_RIGHT_ANSWER_STATUS = 'CHANGE_RIGHT_ANSWER_STATUS'

export default {
  SELECT_NEXT_REPLICA,
  CHECK_ENTERED_WORD,
  REMOVE_LAST_SYMBOL,
  SELECT_REPLICA,
  SELECT_SYMBOL,
  SELECT_ANSWER,
  SELECT_PART,
  PRESELECT_PART,
  SELECT,
  PLAY,
  STOP,

  SET_WORDS_EXERCISE,
  CHANGE_RIGHT_ANSWER_STATUS
}
