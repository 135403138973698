import { Event, Adapter } from '../adapter';
import { GoogleAnalyticsOptions, GoogleAnalyticsWindow } from './interfaces';

import { getCategory } from './categories';
import { getEventName } from './events';

declare let window: GoogleAnalyticsWindow;


export default class GoogleAnalyticsAdapter extends Adapter {
  static stream: Uppercase<string> = 'GOOGLE_ANALYTICS';

  external_id?: string;

  constructor(options?: GoogleAnalyticsOptions) {
    super(options);

    if (options?.google?.enabled === false)
      this.enabled = options?.google?.enabled;
  }

  _init() {
    return this.client = window.ga;
  }

  async _invoke(event: Event) {
    if (!this.enabled)
      return;

    if (!this.client)
      throw new Error('GA counter must be initialized before');

    window.ga('send', {
      hitType: 'event',
      eventCategory: getCategory(event.name),
      eventAction: getEventName(event.name) ,
      eventLabel: event.label || ''
    });
  }

  cleanup(ctx: {[index:string]: any}): {[index:string]: any} {
    if (!ctx.external_id && this.external_id)
      ctx.customer_user_id = this.external_id;

    return ctx;
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (!this.client)
      return;

    // At a later time, once the `userId` value is known,
    // sets the value on the tracker.
    window.ga('set', 'userId', userId);

    // Setting the userId doesn't send data to Google Analytics.
    // You must also use a pageview or event to send the data.
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'sign_in',
      eventAction: 'sign_in'
    });
  }

  getVisitorId() {
    if (!this.client)
      return;

    const trackers = window.ga.getAll();
    if (trackers.length > 0)
      return trackers[0].get('clientId');
  }
}
