import schema from './schema';
import { URLSchemaDSL, URLConfigurationDSL } from './interfaces';
import { ensureFunction, identity, set } from '@2l/utils';
import * as u from './util';

const urls = DSL(schema);

export { lookup };

function setPath(url: URL, value: string) {
  return set(url, 'pathname', `/${value}`);
}

function lookup(provider: string, alias?: string): URL {
  if (!urls[provider])
    throw new Error(`failed on get default link for ${provider} provider`);

  if (alias && !urls[provider][alias])
    throw new Error(`failed on get ${alias} link for ${provider} provider`);

  return urls[provider][alias || 'default'];
}

function DSL(fn: (schema: URLSchemaDSL) => void): { [p: string]: { [p: string]: URL } } {
  const urls: { [provider: string]: { [idx: string]: URL } } = {};
  const mappings: { [provider: string]: { [name: string]: any } } = {};

  const dsl: URLSchemaDSL = {
    url(provider: string, prefix: string,
        mapping: { [name: string]: any }) {
      mappings[provider] = mapping; // keep mappings

      const url = new URL(prefix);

      for (const key in mapping) {
        const fn = ensureFunction(mapping[key], identity);
        const value = fn({ provider });

        if (value)
          url.searchParams.append(key, value);
      }

      if (!urls[provider])
        urls[provider] = { default: url };

      return confDSL(urls, provider);
    },

    from(provider: string, alias?: string): { [name: string]: any } {
      if (!mappings[provider])
        throw new Error(`failed on extract mapping for ${provider} provider`);

      return mappings[provider];
    }
  };

  fn(dsl);

  return urls;
}

function confDSL(urls: { [provider: string]: { [idx: string]: URL } }, provider: string): URLConfigurationDSL {
  const dsl: URLConfigurationDSL = {
    alias(name: string): URLConfigurationDSL {
      urls[provider][name] = urls[provider].default;

      return this;
    },

    tracker(condition: { [name: string]: any }, defaultValue?: string, urlParam?: string, alias?: string): URLConfigurationDSL {
      const trackerId = u.firstOfParam(['adj_t', 'adjust_t', 'adjust_tracker'])
      const source = u.firstOfParam([urlParam || 'utm_source'], 'facebook').toLowerCase();
      const value = trackerId || condition[source] || defaultValue;

      if (alias) {
        const untiedUrl = new URL(urls[provider][alias]);
        setPath(untiedUrl, value);
        urls[provider][alias] = untiedUrl;
      } else {
        for (const i in urls[provider]) {
          const u = urls[provider][i];
          setPath(u, value);
        }
      }

      return this;
    }
  };

  return dsl;
}
