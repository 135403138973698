/* ============
 * Mutation types for the modal module
 * ============
 *
 * The mutation types that are available
 * on the modal module.
 */

export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_OPTIONS = 'SET_OPTIONS'

export default {
  SHOW_MODAL,
  CLOSE_MODAL,
  SET_OPTIONS
}
