<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="props.width"
       :height="props.height"
       viewBox="0 0 18 18"
       :aria-labelledby="props.iconName"
  >
    <title
      :id="props.iconName"
      lang="en"
    >{{ iconName }} icon</title>
    <g :color="props.iconColor">
      <slot />
    </g>
  </svg>
</template>
<script>
// eslint-disable-next-line
import { defineComponent, defineProps } from 'vue'
export default defineComponent({
  name: 'UiIcon'
})
</script>
<script setup>
// eslint-disable-next-line
import { defineProps } from 'vue'

const props = defineProps({
  iconName: {
    type: String,
    default: ''
  },
  width: {
    type: [Number, String],
    default: 18
  },
  height: {
    type: [Number, String],
    default: 18
  },
  iconColor: {
    type: String,
    default: 'currentColor'
  }
})
</script>

<style scoped lang="stylus">
svg {
  transition color 0.3s
}
</style>
