/* ============
 * Mutations for the courses module
 * ============
 *
 * The mutations that are available on the
 * courses module.
 */

import { detect, asArray } from '@2l/utils'

import {
  FIND,
  SELECT,
  UNSELECT,
  SET_ROADMAP,
  SET_ROW_COUNT,
  SET_LAST_VISITED_LESSON_ID
} from './mutation-types.js'

export default {
  [SET_ROADMAP] (state, result) {
    state.map = result
  },
  [SELECT] (state, course) {
    state.selected = detect({ value: course._id }, state.selectable)
    state.active = course || {}
    state.active.lessons = asArray(state.active.lessons || [])
  },
  [UNSELECT] (state) {
    state.selected = null
  },
  [FIND] (state, courses) {
    if (courses.total < 1) { state.selected = state.active = null }

    state.selectable = courses.selectable.filter((course, idx) => {
      return courses.rows[idx].lessonsTotal !== 0
    })

    state.selected = courses.selected
    state.total = courses.total
    state.rows = courses.rows

    state.loaded = true
  },
  [SET_ROW_COUNT] (state, rowCount) {
    state.rowCount = rowCount
  },

  [SET_LAST_VISITED_LESSON_ID] (state, lessonId) {
    state.lastVisitedLessonId = lessonId
  }
}
