import BaseProxy from './Proxy.js'

class AuthProxy extends BaseProxy {
  constructor (parameters = {}) {
    super('api/v22', parameters)
  }

  /**
   * Method used to login.
   *
   * @param {String} username The username.
   * @param {String} password The password.
   *
   * @returns {Promise} The result in a promise.
   */
  login ({ login, password, fields }) {
    // TODO: Prevent XCSRF-type of attack

    const data = {
      username: login,
      password,

      _fields: fields
    }

    return this.submit('post', `${this.endpoint}/auth/login`, data)
  }

  /**
   * Method used to request restore password
   * @param  {String} email [User email]
   * @returns {Promise} The result in a promise.
   */
  restore (email) {
    const data = { email }

    return this.submit('post', `${this.endpoint}/user/restore-password`, data)
  }

  reset ({ newPassword, token }) {
    const data = { password: newPassword, token }

    return this.submit('post', `${this.endpoint}/user/reset-password`, data)
  }

  change ({ newPassword }) {
    const data = { newPassword }

    return this.submit('put', `${this.endpoint}/user/password`, data)
  }

  /**
   * Method used to auth oven 3rd-parties services
   *
   * @param {Object} payload
   * @param {String} payload.network  - can be presented as facebook,
   *                                    google, vkontakte
   * @param {String} payload.accessToken
   * @param {String} [payload.lang]
   * @param {String} [payload.email]
   * @param {String} [payload.userId]
   *
   * @returns {Promise} The result in a promise.
   */
  auth ({
    lang, network, accessToken, email, userId, fields
  }) {
    const data = {
      _fields: fields, accessToken, network, lang
    }

    if (lang) { data.lang = lang }
    if (email) { data.email = email }
    if (userId) { data.userId = userId }

    return this.submit('post', `${this.endpoint}/user/session/oauth`, data)
  }

  authWithApple () {
    return this.submit('get', `${this.endpoint}/auth/apple/callback`)
  }

  hello ({ token, lang }) {
    const data = { token, lang }
    return this.submit('post', `${this.endpoint}/auth/hello`, data)
  }

  /**
   * Method used to logout the user.
   *
   * @returns {Promise} The result in a promise.
   */
  logout () {
    return this.submit('delete', `${this.endpoint}/auth/logout`)
  }

  /**
   * Method used to register the user.
   *
   * @param {Object} data The register data.
   *
   * @returns {Promise} The result in a promise.
   */
  register ({
    lang, login, password, fields
  }) {
    const data = {
      lang,
      login,
      password,

      email: login,
      _fields: fields
    }

    return this.submit('post', `${this.endpoint}/users`, data)
  }

  emailRegister (data) {
    return this.submit('post', `${this.endpoint}/users/email`, data)
  }
}

export default AuthProxy
