/* ============
 * State of the exercise module
 * ============
 *
 * The initial state of the exercise module.
 */

export default {
  // Unique identifier
  _id: null,

  // Core data
  type: null,
  courseId: null,
  lessonId: null,

  // Boolean properties
  isCompleted: false,
  isRepeatable: false,

  // Text content
  text: null,
  hint: null,
  translation: null,
  description: null,
  origin: null,
  translatedMeanings: null,
  exampleOrigin: null,
  exampleTranslation: null,

  // Media content
  image: null,
  video: null,

  // Static hashes
  voice: {},
  parts: {},
  avatars: {},
  symbols: {},
  answers: {},
  replicas: {},
  partsByIndex: {},
  correctPartsIndex: {},

  // Boolean flags to check state of exercise
  readyToCheck: null,

  // Mistaces counter
  mistakes: 0,

  // Additional properties
  correctWord: null,
  selectedPart: null,
  correctPhrase: null,
  correctPhraseKey: null,
  selectedAnswer: null,
  selectedResult: null,
  verifiedResult: null,
  currentReplica: null,
  selectedReplica: null,
  correctWordOriginal: null,

  correctWordLength: null,
  correctPhraseLength: null,

  correctVariants: 0,
  countSelectedSymbols: 0,

  questions: [],
  selectedParts: [],
  selectedReplicas: [],
  selectedWordComponents: [],
  selectablePartsShuffled: [],

  selectedSymbols: {},
  selectableParts: {},

  playableKeys: {},
  exerciseForWords: null,
  intermediateResult: null,
  selectedRightAnswer: false
}
