/* ============
 * Courses Transformer
 * ============
 *
 * The transformer for courses collection.
 */

import Transformer from './Transformer.js'

export default class CoursesTransformer extends Transformer {
  /**
   * Method used to transform a fetched collection.
   *
   * @param response the result of API call
   *
   * @returns {Object} transformed response.
   */
  static prefetch ({ rows = [], total = 0 }) {
    const selectable = []
    const data = {
      total, rows, selectable, selected: null
    }

    for (const row of rows) { selectable.push({ value: row._id, label: row.title }) }

    return data
  }

  /**
   * Method used to transform a single entry of courses
   *
   * @param course
   * @param meta   [meta={}]
   *
   * @returns {Object} transformed course.
   */
  static fetch (course, meta = {}) {
    const { lessons = [] } = course

    for (let i = 0; i < lessons.length; i++) { lessons[i].order = i + 1 }

    return Object.assign(course, { meta })
  }
}
