export interface Experiment {
  experimentId: string;
  type: string;
  optionId?: string;
  values?: { [key: string]: any };
  expiredAt?: number;
  joined?: false | true;
  conditions?: [{ key: string }];
}

export interface RawExperimentOption {
  values: { [key: string]: any } | string;
}

export interface RawExperiment {
  experimentId: string;
  type: 'conversion' | 'class' | 'landing' | 'external';
  portion: number;
  kickstart?: boolean;
  options: { [key: string]: RawExperimentOption };
  ttlMillis: number;
  conditions?: { [key: string]: any };
}

export interface Booking {
  externalId?: string;
  eventSourceUrl: string;

  experiment?: Experiment;

  facebookClickId?: string | undefined;
  facebookBrowserId?: string | undefined;

  adjustWebUUID?: string | undefined;
  adjustTrackerId?: string | undefined;

  twitterClickId?: string | undefined;

  googleGclid?: string | undefined;
  googleWbraid?: string | undefined;
  googleGbraid?: string | undefined;

  oceanengineCampaign?: string | undefined;
  oceanengineCampaignId?: string | undefined;
  oceanengineAdGroup?: string | undefined;
  oceanengineCreative?: string | undefined;
  oceanengineCreativeId?: string | undefined;
  oceanengineClickId?: string | undefined;
}


export interface RawRemoteConfigParamValue {
  value?: string;
}

export interface RawRemoteConfigParam {
  defaultValue: RawRemoteConfigParamValue;
  conditionalValues: { [key: string]: RawRemoteConfigParamValue }
}

export interface RawRemoteConfig {
  parameters: { [key: string]: RawRemoteConfigParam }
}

export const LOCAL_SOURCE = 'local';
export const REMOTE_SOURCE = 'remote';

export type SOURCE_MODE = typeof LOCAL_SOURCE | typeof REMOTE_SOURCE
