/* ============
 * Materials Transformer
 * ============
 *
 * The transformer for the materials.
 */

import {
  G, pick, map, indexBy
} from '@2l/utils'
import Transformer from './Transformer.js'

const buildMaterials = pick(
  {
    _id: G('material._id'),
    origin: G('material.origin'),
    image: G('material.image'),
    languageLevel: G('material.languageLevel'),
    isOriginal: G('material.isOriginal')
  }
)

export default class MaterialsTransformer extends Transformer {
  /**
   * Method used to transform a fetched materials.
   *
   * @param account The fetched materials.
   *
   * @returns {Object} The transformed materials.
   */
  static fetch (learningMaterials) {
    const materials = map(buildMaterials, learningMaterials)
    const materialIds = indexBy('materialId', learningMaterials)

    return { materials, materialIds }
  }
}
