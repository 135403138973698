import log from 'loglevel';
import * as u from './links/util';
import * as Links from './links';
import Tracker from './tracker';
import { Stream } from './adapters/adapter';
import * as adapters from './adapters'

log.setDefaultLevel('info');

declare global {
  interface EWA {
    LINKS: { lookup(provider: string, alias?: string): URL },
    ANALYTICS: Tracker,
    STREAMS: { [key: Stream]: Stream }
  }
  interface Window { EWA: EWA; }
}

export const tracker = Tracker.GetTrackerByMediaSource(u.param('utm_source', ''));

tracker.on('schema.adapter.ready', (readyAdapters) => {
  const event = new CustomEvent('EWA.ANALYTICS:initialized', {
    detail: { tracker, readyAdapters }
  });
  window.dispatchEvent(event)
});

tracker.init().catch(e => log.error(e))

if (!window.EWA)
  window.EWA = {
    LINKS: Links,
    ANALYTICS: tracker,
    STREAMS: {
      [adapters.AdjustAdapter.getStream()]:
        adapters.AdjustAdapter.getStream(),
      [adapters.YandexAdapter.getStream()]:
        adapters.YandexAdapter.getStream(),
      [adapters.TwitterAdapter.getStream()]:
        adapters.TwitterAdapter.getStream(),
      [adapters.FacebookAdapter.getStream()]:
        adapters.FacebookAdapter.getStream(),
      [adapters.GoogleAnalyticsAdapter.getStream()]:
        adapters.GoogleAnalyticsAdapter.getStream(),
      [adapters.CRMAdapter.getStream()]:
        adapters.CRMAdapter.getStream()
    }
  };
else
  window.EWA.ANALYTICS = tracker;
