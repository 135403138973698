import BaseProxy from './Proxy.js'

class PlanProxy extends BaseProxy {
  /**
   * The constructor for the PlanProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/plan', parameters)
  }

  /**
   * Method used to retrieve plan information
   *
   * @param {String} planId
   *
   * @returns {Promise} The result in a promise.
   */
  info (planId) {
    return this.submit('get', `${this.endpoint}/${planId}`)
  }

  list (planIds) {
    const params = { planIds }

    return this.submit('get', `${this.endpoint}`, { params })
  }
}

export default PlanProxy
