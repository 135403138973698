import { Event, Adapter } from '../adapter';
import { param } from '../../links/util'
import { OceanEngineAnalyticsWindow, OceanEngineOptions } from './interfaces';

declare let window: OceanEngineAnalyticsWindow;

export default class OceanengineAdapter extends Adapter {
  static stream: Uppercase<string> = 'OCEANENGINE';
  static trackerNumber = '10';

  assets_id?: string;

  constructor(options?: OceanEngineOptions) {
    super(options);

    if (options?.oceanengine?.enabled === false)
      this.enabled = options.oceanengine.enabled;

    if (!options || !options.oceanengine || this.enabled === false)
      return;

    if (options.oceanengine?.assets_id)
      this.assets_id = options.oceanengine.assets_id;
  }

  _init() {
    if (this.client || !window._baq || this.enabled === false)
      return this.client;

    this.client = window._baq;

    // The Landing Page Insights SDK will collect landing page visit data,
    // but will not collect private information such as user name and mobile phone numbers.
    // After enabling the insight sdk, you can go to the advertising platform - landing page report
    // to analyze the page data and optimize the conversion effect. Click to view the instructions
    // for using the Landing Page Report .
    // @see https://event-manager.oceanengine.com/docs/8650/jssdk_docs/
    // The following section is the landing page insight code (not enabled by default):
    this.client.on('insight');

    return this.client;
  }

  async _invoke(event: Event) {
    if (this.enabled === false)
      return;

    if (!this.client)
      throw new Error('OceanEngine pixel must be initialized before');

    const payload = { assets_id: this.assets_id, ...event.value };
    let eventType = '';

    switch (event.name) {
      case 'StartTrial':
        eventType = 'active';
        // eslint-disable-next-line no-fallthrough
      case 'Purchase':
        Object.assign(payload, {
          product_name: 'subscription', // TODO: pass real name of subscription to service
        });
        return this.client.track(eventType || 'active_pay', payload);
      case 'landing':
        return this.client.track('pageview', payload);
      default:
        return;
    }
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (!this.client)
      return;
  }

  getVisitorId(): string | undefined {
    if (param('utm_source') === 'oceanengine')
      return param('clickid')
  }
}
