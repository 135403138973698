/* ============
 * State of the courses module
 * ============
 *
 * The initial state of the courses module.
 */

export default {
  selectable: [],
  rows: [],

  rowCount: null,
  selected: null,
  active: null,

  loaded: false,
  total: null,

  lastVisitedLessonId: null,

  map: {}
}
