import store from '../store'
import countries from '../data/tier1Contries'

let timer, timerStart, timeSpentOnSite

const getTimeSpentOnSite = function () {
  timeSpentOnSite = localStorage.getItem('timeSpentOnSite')
  timeSpentOnSite = timeSpentOnSite ? Number(timeSpentOnSite) : 0
  return timeSpentOnSite
}

export const startCounting = function () {
  const promoUser = localStorage.getItem('promoUser')
  if (!promoUser) {
    timerStart = Date.now()
    timer = setInterval(() => {
      timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart)
      localStorage.setItem('timeSpentOnSite', timeSpentOnSite)
      timerStart = Number(Date.now())

      // eslint-disable-next-line radix
      if (parseInt(timeSpentOnSite / 1000) >= 300) {
        store.dispatch('modal/open', 'promo')
        store.dispatch('modal/setOptions', {
          modalClass: 'promo__modal'
        })
        store.dispatch('offers/setPromoUser')
        clearInterval(timer)
        localStorage.removeItem('timeSpentOnSite')
      }
    }, 1000)
  }
}

export const endTimeCount = function () {
  clearInterval(timer)
  localStorage.removeItem('timeSpentOnSite')
}

export const getCookie = function (name) {
  let value = document.cookie
  let start = value.indexOf(` ${name}=`)
  if (start === -1) { start = value.indexOf(`${name}=`) }
  if (start === -1) { value = null } else {
    start = value.indexOf('=', start) + 1
    let end = value.indexOf(';', start); if (end === -1) { end = value.length }
    value = unescape(value.substring(start, end))
  } return value
}

export const setCookie = function (name, val, exDays) {
  const exDate = new Date()
  exDate.setDate(exDate.getDate() + exDays)
  const value = escape(val) + ((exDays == null)
    ? ''
    : `; expires=${exDate.toUTCString()}`)
  document.cookie = `${name}=${value}`
}

export const setTierSupport = function (tierSupport = false) {
  const code = store.getters['account/getUserCountry']
  const tier = window.EWA.ANALYTICS.getConfigBooleanValue('tier_support')

  if (tier || tierSupport) {
    if (Object.keys(countries).find(v => code === v)) {
      localStorage.setItem('tier', 'tier1')
    } else {
      localStorage.setItem('tier', 'tier2')
    }
  } else localStorage.removeItem('tier')
}
