/* ============
 * Mutation types for the general module
 * ============
 *
 * The mutation types that are available
 * on the general module.
 */

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SET_LOCALE_LOADED = 'SET_LOCALE_LOADED'

export default {
  START_LOADING,
  STOP_LOADING,

  SET_LOCALE_LOADED
}
