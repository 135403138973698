import BaseProxy from './Proxy.js'

class CoursesProxy extends BaseProxy {
  /**
   * The constructor for the CoursesProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/courses', parameters)
  }

  getRoadmap () {
    return this.submit('get', `${this.endpoint}/roadmap`)
  }
}

export default CoursesProxy
