import BaseProxy from './Proxy.js'

class SubscriptionPaypalProxy extends BaseProxy {
  /**
     * The constructor for the SubscriptionPaypalProxy.
     *
     * @param {Object} parameters The query parameters.
     */
  constructor (parameters = {}) {
    super('api/v20/', parameters)
  }

  subscribe (data) {
    return this.submit('post', `${this.endpoint}subscription/paypal`, data)
  }

  createOrder (data) {
    return this.submit('post', `${this.endpoint}checkout/paypal/order`, data)
  }

  captureOrder (id) {
    return this.submit('post', `${this.endpoint}checkout/paypal/order/${id}/capture`)
  }
}

export default SubscriptionPaypalProxy
