import { EventEmitter } from 'tsee';

import {
  AdapterConstructor,

  GoogleAnalyticsAdapter,
  OceanengineAdapter,
  FacebookAdapter,
  TwitterAdapter,
  AdjustAdapter,
  YandexAdapter,
  CRMAdapter,
  Adapter
} from '../adapters';

import { EventType } from '../events';
import { Options } from '../adapters/adapter';

export default class Schema extends EventEmitter {
  events: {[index:string]: any};
  contexts: {[index:string]: (context: any) => any};

  adapters: Adapter[];

  initialized = false;
  readyAdapters  = 0;

  constructor() {
    super();
    this.events = {};
    this.contexts = {};
    this.adapters = [];
  }

  init(options?: Options) {
    if (this.initialized)
      return;

    this.initialized = true;

    for (const Class of [
      FacebookAdapter,
      YandexAdapter,
      AdjustAdapter,
      TwitterAdapter,
      OceanengineAdapter,
      GoogleAnalyticsAdapter,
      CRMAdapter
    ]) {
      const context = this.contexts[Class.getStream()];

      if (!options)
        options = {};
      options.context = context;

      const adapter = new Class(options);
      adapter.on('ready', adapter => {
        this.readyAdapters++;
        this.emit('adapter.ready', adapter);
      });

      adapter.start();

      // TODO: add promise to await ready adapters
      this.adapters.push(adapter);
    }
  }

  setExtraContext(adapter: AdapterConstructor, fn: (context: any) => any) {
    this.contexts[adapter.getStream()] = fn;
  }

  getEvents(adapter: Adapter): any[] | undefined {
    const konstructor = (adapter.constructor as AdapterConstructor);
    return this.events[konstructor.getStream()];
  }

  addEvent(adapter: AdapterConstructor, eventType: EventType, eventNames: Function[], builder: Function) {
    const registry = this.events[adapter.getStream()] || {};

    if (!this.events[adapter.getStream()])
      this.events[adapter.getStream()] = registry;

    const events = registry[eventType] = registry[eventType] || [];

    for (const eventName of eventNames)
      events.push({ eventName, builder });
  }
}
