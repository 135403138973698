import { Event, Adapter } from '../adapter';
import { YandexOptions, YandexWindow } from './interfaces';
import { detect, extend } from '@2l/utils';
import goals from './goals';

declare let window: YandexWindow;

export default class YandexMetrikaAdapter extends Adapter {
  static stream: Uppercase<string> = 'YANDEX_METRIKA';
  static trackerNumber = '7';

  external_id?: string;
  counter_id?: number | string | undefined;

  constructor(options?: YandexOptions) {
    super(options);
    if (options?.yandex?.enabled === false)
      this.enabled = options?.yandex?.enabled

    if (!options || !options.yandex)
      return;

    if (options.yandex.counter_id)
      this.counter_id = options.yandex.counter_id;
  }

  _init() {
    if (this.enabled === false)
      return;

    if (this.client)
      return this.client;

    const metrica = window?.Ya?.Metrika2;
    const counters = metrica ? metrica.counters() : [];
    const found = detect({ id: this.counter_id }, counters);

    if (!found && counters.length)
      this.counter_id = counters[0].id;

    if (!found && this.counter_id)
      window.ym(this.counter_id, 'init', {
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        triggerEvent: true,
        accurateTrackBounce: true
      });
    else if (found)
      this.counter_id = found.id;

    if (this.counter_id && window.ym)
      this.client = window.ym;

    return this.client;
  }

  async _invoke(event: Event) {
    if (this.enabled === false)
      return;

    if (!this.client)
      throw new Error('Yandex.Metrika2 counter must be initialized before');

    const goal = goals[event.name] || event.name;

    await new Promise((resolve) => {
      this.client(this.counter_id, 'reachGoal', goal,
        extend(event.context, event.value), resolve);
    });
  }

  cleanup(ctx: {[index:string]: any}): {[index:string]: any} {
    if (!ctx.external_id && this.external_id)
      ctx.customer_user_id = this.external_id;

    return ctx;
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (!this.client || !this.counter_id)
      return;

    window.ym(this.counter_id, 'setUserID', userId);
  }

  getVisitorId(): Promise<string> | undefined {
    if (!this.client || !this.counter_id)
      return;

    return new Promise((resolve, _) => {
      this.client(this.counter_id, 'getClientID', (clientId: string) => {
        resolve(clientId);
      });
    });
  }
}
