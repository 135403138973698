<template>
  <div class="switch-block" :class="[cssClass]">
    <label class="switch">
      <input
          v-model="value"
          type="checkbox"
      >
      <span class="slider round"></span>
    </label>
    <span class="switch-title">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'UiSwitch',
  props: {
    label: String,
    modelValue: Boolean,
    inputValue: String,
    cssClass: String
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
  box-shadow: none;
  margin: 0
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: web-grey-200;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: web-blue-500;
}

input:focus + .slider {
  box-shadow: 0 0 1 pxweb-blue-500;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch-block {
  display: flex;
  align-items: center;
}

.switch-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: web-grey-700;
  margin-left: 10px;
}

/* Size xs styles */
.switch-block.size-xs {
  .switch {
    width: 40px;
    height: 24px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  .slider:before {
    height: 18px;
    width: 18px;
    bottom: 3px;
    left: 3px;
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.25);
  }

  .switch-title {
    margin-left: 0;
  }
}
</style>
