/* ============
 * State of the notification module
 * ============
 *
 * The initial state of the notification module.
 */

export default {
  waitingQueue: [],
  activeQueue: []
}
