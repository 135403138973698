import UiButton from './UiButton.vue'
import UiSwitch from './UiSwitch.vue'
import UiRange from './UiRange.vue'
import UiIcon from './UiIcon.vue'
import UiCheckbox from './UiCheckbox.vue'
import UiInput from './UiInput.vue'

export default [
  UiButton,
  UiSwitch,
  UiRange,
  UiIcon,
  UiCheckbox,
  UiInput
]
