import { tracker } from '@/plugins/vue-any'

export const landings = [
  // Сhat Onboarding
  {
    path: '/onboardingchat',
    name: 'onboardingchat.index',
    component: () => import('@/pages/ChatOnboarding/Index'),
    meta: {
      auth: false,
      type: 'onboarding_chat',
      experiment_layer: 'class'
    }
  },
  {
    path: '/onboardingchatfb',
    name: 'onboardingchatfb.index',
    component: () => import('@/pages/ChatOnboarding/Index.vue'),
    meta: {
      auth: false,
      type: 'onboardingchat_w2m',
      experiment_layer: 'class'
    }
  },
  // Classic onboarding
  {
    path: '/onboarding',
    name: 'onboarding.index',
    component: () => import('@/pages/Onboarding/RegularVersion'),
    meta: {
      auth: false,
      type: 'onboardingregular_vue',
      version: 1.0
    }
  },

  {
    path: '/onboarding-web2m-v1',
    name: 'onnboardingAD.index',
    component: () => import('@/pages/Onboarding/AdVersion'),
    meta: {
      auth: false,
      type: 'onboarding_ad_v1',
      version: 1.0
    }
  },

  {
    path: '/mobile-saver',
    name: 'mobile-saver.index',
    component: () => import('@/pages/Landings/MobileSaver'),
    meta: {
      auth: true,
      experiment_layer: 'class'
    }
  },

  {
    path: '/kickstart',
    name: 'kickstart.index',
    component: () => import('@/pages/Landings/Kickstart'),
    meta: {
      experiment_layer: ['landing', 'store'],
      type: tracker.getConfigStringValue('web_landing_name', 'kickstart')
    }
  }
]
