/* ============
 * Mutations for the language module
 * ============
 *
 * The mutations that are available on the
 * language module.
 */

import { indexBy, filter } from '@2l/utils'
import {
  ENSURE, SELECT, SET_VERSION, SUBSCRIBE, SET_SELECTED_CODE
} from './mutation-types.js'

export default {
  [ENSURE] (state, langs) {
    state.rows = langs
    state.available = indexBy('code', langs)
    state.selectable = filter('isSelectable', langs)
  },

  [SELECT] (state, language) {
    state.selected = language
  },

  [SET_SELECTED_CODE] (state, code) {
    state.selectedCode = code
  },

  [SET_VERSION] (state, version) {
    state.version = version
  },

  [SUBSCRIBE] (state) {
    state.subscribed = true
  }
}
