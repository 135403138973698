/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import {
  CHECK,
  LOGIN,
  LOGOUT,
  REGISTER
} from './mutation-types.js'

import { instance } from '../../../plugins/axios'

export default {
  [CHECK] (state) {
    const token = localStorage.getItem('id_token')
    state.isAuthenticated = !!token

    if (state.isAuthenticated) { instance.defaults.headers.common['X-Session-Id'] = token }

    return state.isAuthenticated
  },

  [REGISTER] (state, params) {
    state.registrationMethod = params.method
  },

  [LOGIN] (state, token) {
    state.isAuthenticated = true
    localStorage.setItem('id_token', token)
    instance.defaults.headers.common['X-Session-Id'] = token
  },

  [LOGOUT] (state) {
    state.isAuthenticated = false
    localStorage.removeItem('id_token')
    instance.defaults.headers.common['X-Session-Id'] = ''
  }
}
