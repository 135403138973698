/* ============
 * Actions for the materials module
 * ============
 *
 * The actions that are available on the
 * materials module.
 */
import Transformer from '@/transformers/MaterialsTransformer'

import * as types from './mutation-types.js'

export async function setLearningMaterials ({ commit }, learningMaterials) {
  const materials = Transformer.fetch(learningMaterials)

  await commit(types.SET_LEARNING_MATERIALS, materials)
}

export async function setMaterialId ({ commit }, material) {
  commit(types.SET_MATERIAL_ID, material)
}

export default {
  setLearningMaterials,
  setMaterialId
}
