/* ============
 * Mutations for the exercise module
 * ============
 *
 * The mutations that are available on the
 * exercise module.
 */

import { invoke, extend } from '@2l/utils'
import {
  SELECT_NEXT_REPLICA,
  CHECK_ENTERED_WORD,
  REMOVE_LAST_SYMBOL,
  SELECT_REPLICA,
  SELECT_SYMBOL,
  SELECT_ANSWER,
  SELECT_PART,
  PRESELECT_PART,
  SELECT,
  PLAY,
  STOP,

  SET_WORDS_EXERCISE,
  CHANGE_RIGHT_ANSWER_STATUS
} from './mutation-types.js'

const join = invoke('join', '')
const lower = invoke('toLowerCase')

export default {
  [SELECT] (state, exercise) {
    state._id = exercise._id

    state.courseId = exercise.courseId
    state.lessonId = exercise.lessonId

    state.isCompleted = exercise.isCompleted
    state.isRepeatable = exercise.isRepeatable

    state.content = exercise.content
    state.answers = exercise.answers

    state.translation = exercise.translation
    state.description = exercise.description
    state.questions = exercise.questions
    state.replicas = exercise.replicas
    state.avatars = exercise.avatars
    state.symbols = exercise.symbols
    state.voice = exercise.voice
    state.video = exercise.video
    state.image = exercise.image
    state.parts = exercise.parts
    state.type = exercise.type
    state.hint = exercise.hint
    state.text = exercise.text
    state.origin = exercise.origin
    state.translatedMeanings = exercise.translatedMeanings
    state.exampleOrigin = exercise.exampleOrigin
    state.exampleTranslation = exercise.exampleTranslation

    state.mistakes = 0
    state.readyToCheck = null
    state.selectedPart = null
    state.verifiedResult = null
    state.selectedResult = null
    state.selectedAnswer = null
    state.selectedReplica = null

    state.countSelectedSymbols = 0

    state.selectablePartsShuffled = exercise.selectablePartsShuffled
    state.correctPhraseLength = exercise.correctPhraseLength
    state.correctWordOriginal = exercise.correctWordOriginal
    state.correctPartsIndex = exercise.correctPartsIndex
    state.correctVariants = exercise.correctVariants
    state.selectableParts = exercise.selectableParts
    state.correctPhrase = exercise.correctPhrase
    state.correctPhraseKey = exercise.correctPhraseKey
    state.partsByIndex = exercise.partsByIndex
    state.correctWord = exercise.correctWord

    state.selectedParts = []
    state.selectedSymbols = {}
    state.selectedReplicas = []
    state.selectedWordComponents = []

    state.playableKeys = exercise.playableKeys

    // Set paused flag in true as default behavior
    state.paused = state.video ? true : null

    // Set the first replica as started
    state.currentReplica = state.replicas
      ? state.replicas[Object.keys(state.replicas)[0]]
      : null
  },
  [PLAY] (state) {
    state.paused = false
  },
  [STOP] (state) {
    state.paused = true
  },
  [SELECT_ANSWER] (state, answer) {
    const { answers } = state

    const correct = state.selectedResult = answers[answer]

    state.selectedAnswer = answer

    if (!correct && answer) { state.mistakes += 1 }
  },
  // eslint-disable-next-line consistent-return
  [SELECT_REPLICA] (state, replica) {
    const { replicas, selectedReplicas } = state

    if (state.selectedReplica === replica) { return false }
    state.selectedReplica = replica

    const countSelectedReplicas = selectedReplicas.length
    const currentReplica = replicas[countSelectedReplicas]

    for (const answer of currentReplica.answers) {
      if (answer.text === replica && !answer.correct) {
        state.mistakes += 1
        state.selectedResult = false
        return false
      }
    }

    state.selectedReplicas = state.selectedReplicas.concat(replica)
    state.selectedResult = true

    // TODO: return default value
  },
  [SELECT_NEXT_REPLICA] (state) {
    if (!state.selectedResult) { return }

    const { replicas, selectedReplicas } = state

    const countSelectedReplicas = selectedReplicas.length
    const nextReplica = replicas[countSelectedReplicas]

    state.selectedReplica = null
    state.currentReplica = nextReplica
    state.selectedResult = true
  },
  [SELECT_PART] (state, part) {
    const {
      selectedParts,
      correctVariants,
      correctPartsIndex
    } = state

    const nextIndex = selectedParts.length

    state.selectedPart = part

    if (correctPartsIndex[nextIndex] === part) { state.selectedParts = state.selectedParts.concat(part) } else { state.mistakes += 1 }

    state.selectedResult = state.selectedParts.length === correctVariants
  },
  [PRESELECT_PART] (state, data) {
    state.selectedPart = data.part
    state.selectedPartIndex = data.index
  },
  [SELECT_SYMBOL] (state, symbol) {
    const {
      symbols,
      selectedSymbols,
      selectedWordComponents
    } = state

    if (symbols[symbol] !== selectedSymbols[symbol]) {
      state.selectedSymbols = extend(state.selectedSymbols, {
        [symbol]: (selectedSymbols[symbol] || 0) + 1
      })

      state.countSelectedSymbols = selectedWordComponents.push(symbol)
    }
    const intermediateResult = checkSymbolSelection(state)

    actualizeState(state)

    return intermediateResult
  },
  [REMOVE_LAST_SYMBOL] (state) {
    const {
      selectedSymbols,
      selectedWordComponents
    } = state

    const symbol = selectedWordComponents.pop()

    state.selectedSymbols = extend(state.selectedSymbols, {
      [symbol]: (selectedSymbols[symbol] || 0) - 1
    })

    state.countSelectedSymbols = selectedWordComponents.length
    actualizeState(state)
  },
  [CHECK_ENTERED_WORD] (state) {
    const result = state.selectedWordComponents.join('')

    if (result === state.verifiedResult) { return }
    if (!state.selectedResult) { state.mistakes += 1 }

    state.verifiedResult = result
  },
  [SET_WORDS_EXERCISE] (state, exercise) {
    state.symbols = exercise.symbols
    state.correctWordOriginal = exercise.correctWordOriginal
    state.text = exercise.text
    state.playableKeys = exercise.playableKeys
    state.currentPlayableKeys = exercise.currentPlayableKeys
    state.selectedSymbols = exercise.selectedSymbols
    state.countSelectedSymbols = exercise.countSelectedSymbols
    state.selectedWordComponents = exercise.selectedWordComponents
    state.readyToCheck = exercise.readyToCheck
    state.exerciseForWords = exercise.exerciseForWords
    state.verifiedResult = exercise.verifiedResult
    state.intermediateResult = exercise.intermediateResult
    state.mistakes = exercise.mistakes
    state.voice = exercise.voice
  },
  [CHANGE_RIGHT_ANSWER_STATUS] (state, status) {
    state.selectedRightAnswer = status
  }
}

function actualizeState (state) {
  const {
    countSelectedSymbols,
    correctWordOriginal,
    selectedWordComponents
  } = state
  const correctWordOriginalLength = correctWordOriginal &&
    correctWordOriginal.length
  const result = join(selectedWordComponents) === lower(correctWordOriginal)

  state.readyToCheck = countSelectedSymbols === correctWordOriginalLength
  state.selectedResult = result
  state.verifiedResult = null
}

function checkSymbolSelection (state) {
  const {
    selectedWordComponents,
    countSelectedSymbols,
    correctWordOriginal
  } = state
  const incompleteCorrectWordOriginal = correctWordOriginal.substring(
    0, countSelectedSymbols
  )
  const currentPlayableKeys = {
    [selectedWordComponents.join('')]: incompleteCorrectWordOriginal
  }
  const result = currentPlayableKeys[incompleteCorrectWordOriginal]

  state.currentPlayableKeys = currentPlayableKeys
  state.intermediateResult = result

  if (!result) {
    state.mistakes += 1
    state.intermediateResult = ''
  }

  return result
}
