import Axios from 'axios'
import * as Sentry from '@sentry/vue'
const resource = new URL(window.location.href)

const developmentId = __DEVELOPMENT_SESSION_ID__ ||
  localStorage.getItem('developmentId')

const common = {
  Accept: 'application/json',
  'X-Platform-Id': '255c5893-e2a4-4860-83d8-c2e30433e9f6',
  'X-Requested-With': 'XMLHttpRequest'
}

if (developmentId) { common['X-Session-Id'] = developmentId }

const API_URL = resource.searchParams.get('api_url') || __API_URL__

export const instance = Axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: { common }
})

window.addEventListener('EWA.ANALYTICS:initialized', event => {
  onInitializeTracker(event.detail.tracker)
}, { once: true })

// if it has initialized
if (window.EWA) { onInitializeTracker(window.EWA.ANALYTICS) }

function onInitializeTracker (tracker) {
  if (!tracker) { return }

  tracker.getHeaders()
    .then(headers => Object.assign(instance.defaults.headers.common, headers))
    .catch(Sentry.captureException)
}

export default { instance }
