const countries = {
  US: 'United States of America (the)',
  FR: 'France',
  ES: 'Spain',
  DE: 'Germany',
  IT: 'Italy',
  CA: 'Canada',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  IL: 'Israel',
  KR: 'Korea',
  JP: 'Japan',
  PT: 'Portugal',
  BE: 'Belgium',
  AT: 'Austria',
  AE: 'United Arab Emirates (the)',
  AU: 'Australia',
  FI: 'Finland',
  SE: 'Sweden',
  GR: 'Greece',
  CY: 'Cyprus',
  NO: 'Norway',
  DK: 'Denmark',
  PA: 'Panama',
  CN: 'China',
  HU: 'Hungary',
  CZ: 'Czech Republic',
  GE: 'Georgia',
  NL: 'Netherlands',
  BG: 'Bulgaria',
  IE: 'Ireland',
  CH: 'Switzerland'
}

export default countries
