/* eslint-disable */
/* ============
 * Actions for the general module
 * ============
 *
 * The actions that are available on the
 * general module.
 */
import utils from '@2l/utils';
import helpers from '@/lib/helpers';

import {
  STOP_LOADING,
  START_LOADING,
  SET_LOCALE_LOADED,
} from './mutation-types.js';

const { env } = utils;
const { uuidV4: uuid } = helpers;

export const startLoading = ({ commit }) => {
  commit(START_LOADING);
};

export const stopLoading = ({ commit }) => {
  commit(STOP_LOADING);
};

export const setLocaleLoaded = async ({ commit }) => {
  await commit(SET_LOCALE_LOADED);
};

export default {
  startLoading,
  stopLoading,

  setLocaleLoaded
};
