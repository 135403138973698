<template>
  <span
      :class="['btn_default', btnClass, btnTapped, btnDisabled]"
      @click.self="click"
  >
    <slot name="icon"></slot>
    <slot/>
  </span>
</template>

<script>
import '@/assets/stylus/components/button.styl'

export default {
  name: 'UiButton',
  data () {
    return {
      btnTapped: ''
    }
  },
  props: {
    btnClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    btnDisabled () {
      return this.disabled ? `${this.btnClass}-disabled` : ''
    }
  },
  methods: {
    click () {
      this.btnTapped = `${this.btnClass}-tapped`
    }
  }
}
</script>
