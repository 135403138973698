/* ============
 * Mutation types for the notification module
 * ============
 *
 * The mutation types that are available
 * on the notification module.
 */

export const PUSH = 'PUSH'
export const PULL = 'PULL'

export const STOP_PULLING = 'STOP_PULLING'
export const START_PULLING = 'START_PULLING'

export const FILL_ACTIVE_QUEUE = 'FILL_ACTIVE_QUEUE'
export const CLEAR_ACTIVE_QUEUE = 'CLEAR_ACTIVE_QUEUE'

export default {
  PULL,
  PUSH,

  STOP_PULLING,
  START_PULLING,

  FILL_ACTIVE_QUEUE,
  CLEAR_ACTIVE_QUEUE
}
