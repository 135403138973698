/* ============
 * Actions for the modal module
 * ============
 *
 * The actions that are available on the
 * modal module.
 */

import {
  SHOW_MODAL,
  CLOSE_MODAL,
  SET_OPTIONS
} from './mutation-types.js'

export default {
  open,
  close,
  setOptions
}

export async function open ({ commit }, componentName) {
  await commit(SHOW_MODAL, componentName)
}

export async function close ({ commit }, name) {
  await commit(CLOSE_MODAL)
  if (name === 'promo') { localStorage.setItem('promoPopupClosed', 'true') }
}

export async function setOptions ({ commit }, options) {
  await commit(SET_OPTIONS, options)
}
