import { pick } from '@2l/utils';
import { twclid } from './helpers';
import { Event, Adapter } from '../adapter';
import { TwitterAnalyticsWindow, TwitterOptions } from './interfaces';

declare let window: TwitterAnalyticsWindow;


export default class TwitterAdapter extends Adapter {
  static stream: Uppercase<string> = 'TWITTER';
  static trackerNumber = '9';

  external_id?: string;
  app_id?: string;

  twclid?: string;

  constructor(options?: TwitterOptions) {
    super(options);

    if (options?.twitter?.enabled === false)
      this.enabled = options?.twitter?.enabled

    if (!options || !options.twitter || this.enabled === false)
      return;

    if (options.twitter.app_id)
      this.app_id = options.twitter.app_id;

    this.twclid = twclid() || undefined;

    if (this.twclid) // keep twclid
      localStorage.setItem('_ewa._twclid', this.twclid);

    this.cleanup = pick('value', 'currency', 'email_address', 'external_id');
  }

  _init() {
    if (this.enabled === false)
      return;

    if (this.client || !window.twq)
      return this.client;

    this.client = window.twq;

    return this.client;
  }

  async _invoke(event: Event) {
    if (this.enabled === false)
      return;

    if (!this.client)
      throw new Error('Twitter pixel must be initialized before');

    const eventValue = await this._enrichEventValue(this.cleanup(event.value));

    switch (event.name) {
      case 'StartTrial':
        /*
        for example: {
          value: null, // use this to pass the value of the conversion (e.g. 5.00)
          currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
          conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
          email_address: null // use this to pass a user’s email address
        }
        */
        return this.client('track', 'tw-odlzl-odlzn', eventValue);
      case 'Purchase':
        return this.client('track', 'tw-odlzl-odlzo', eventValue);
      case 'landing':
        return this.client('track', 'tw-odlzl-odlzp');
      default:
        return;
    }
  }

  async _enrichEventValue(value: any) {
    const { twclid } = this;

    if (twclid)
      value.twclid = await this.hash(twclid);
    if (value.email_address)
      value.email_address = await this.hash(value.email_address);

    return super.cleanup(value);
  }

  setCustomerUserId(userId: string) {
    this.external_id = userId;

    if (!this.client)
      return;
  }

  getVisitorId() {
    return this.twclid;
  }
}
