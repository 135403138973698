/* ============
 * Actions for the exercise module
 * ============
 *
 * The actions that are available on the
 * exercise module.
 */

import { keys } from '@2l/utils'

import Transformer from '@/transformers/ExerciseTransformer'

import * as types from './mutation-types.js'

export const select = function ({ commit }, exercise) {
  commit(types.SELECT, Transformer.fetch(exercise))
}

export const play = ({ commit }) => commit(types.PLAY)
export const stop = ({ commit }) => commit(types.STOP)

export function selectAnswer ({ commit, state }, answer) {
  commit(types.SELECT_ANSWER, answer)

  const { selectedResult, playableKeys } = state

  return selectedResult ? keys(playableKeys)[0] : false
}

export function selectPart ({ commit, state }, part) {
  commit(types.SELECT_PART, part)

  const { playableKeys, selectedResult } = state

  return selectedResult ? keys(playableKeys)[0] : false
}

export function preSelectPart ({ commit }, data) {
  commit(types.PRESELECT_PART, data)
}

export async function selectReplica ({ commit, state }, replica) {
  commit(types.SELECT_REPLICA, replica)

  const { selectedResult } = state

  return selectedResult ? replica : false
}

export async function selectNextReplica ({ commit, state }) {
  commit(types.SELECT_NEXT_REPLICA)

  const { currentReplica } = state

  return currentReplica || false
}

export async function selectSymbol ({ commit, state }, symbol) {
  commit(types.SELECT_SYMBOL, symbol)

  const { readyToCheck, exerciseForWords } = state
  const { intermediateResult } = state

  if (exerciseForWords && !intermediateResult) { commit(types.REMOVE_LAST_SYMBOL) }

  return readyToCheck
}

export async function removeLastSymbol ({ commit, state }) {
  const { readyToCheck } = state
  commit(types.REMOVE_LAST_SYMBOL)

  return readyToCheck
}

export async function checkEnteredWord ({ commit, state }) {
  commit(types.CHECK_ENTERED_WORD)

  const { playableKeys, selectedResult } = state

  return selectedResult ? keys(playableKeys)[0] : false
}

export default {
  play,
  stop,
  select,
  selectPart,
  preSelectPart,
  selectAnswer,
  selectSymbol,
  selectReplica,
  removeLastSymbol,
  checkEnteredWord,
  selectNextReplica
}
