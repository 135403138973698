import BaseProxy from './Proxy.js'

class SubscriptionProxy extends BaseProxy {
  /**
   * The constructor for the PurchaseProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/v12/subscriptions', parameters)
  }

  subscribe (data) {
    return this.submit('post', `${this.endpoint}/stripe`, data)
  }

  cancel ({ _id }) {
    return this.submit('post', `${this.endpoint}/${_id}/cancel`)
  }

  unsubscribe ({ _id }) {
    return this.submit(
      'post',
      `${this.endpoint}/${_id}/unsubscribe`
    )
  }
}

export default SubscriptionProxy
