/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the account module.
 */

export const FIND = 'FIND'
export const SET_LANG = 'SET_LANG'
export const UPDATE_LANG = 'UPDATE_LANG'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const SET_MATERIAL_ACCESS_MODE = 'SET_MATERIAL_ACCESS_MODE'
export const RESTORE_MATERIAL_ACCESS_MODE = 'RESTORE_MATERIAL_ACCESS_MODE'
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'
export const SET_VISIBLE_PREMIUM_PROMO = 'SET_VISIBLE_PREMIUM_PROMO'

export default {
  FIND,
  SET_LANG,
  UPDATE_LANG,
  UPDATE_PROFILE,
  UPDATE_EMAIL,
  UPDATE_SETTINGS,
  SET_MATERIAL_ACCESS_MODE,
  RESTORE_MATERIAL_ACCESS_MODE,
  UNSUBSCRIBE_SUCCESS,
  SET_USER_LOCATION,
  SET_VISIBLE_PREMIUM_PROMO
}
