<template>
  <div>
    <div class="notifications" v-if="$store.state.notifications">
      <div v-if="displayModal">
        <div
          v-for="notification of $store.state.notifications.activeQueue"
          :key="notification"
          :class="notification.type"
          class="notification"
        >

          <h3> {{ $t(notification.header) }} </h3>
          <div> {{ $t(notification.message) }} </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/stylus/components/notification.styl'
/* ============
   * Navbar Component
   * ============
   *
   * A basic navbar component.
   */
import utils from '@2l/utils'

import { mapGetters } from 'vuex'

const { G, eql } = utils

export default {
  /**
     * The name of the component.
     */
  name: 'notification',

  /**
     * The methods that the layout can use.
     */
  methods: {
    /**
       * Will log the user out.
       */
    logout () {
      // logout action
    }
  },

  computed: {
    ...mapGetters('notifications', {
      loaded: 'isLoaded',
      firstNotification: 'getFirstNotification'
    }),

    outputType: G('firstNotification.outputType'),
    displayModal: eql('outputType', 'modal')
  },

  /**
     * The data that can be used by the page.
     *
     * @returns {Object} The view-model data.
     */
  data () {
    return {
      activeQueue: [],
      rows: []
    }
  },

  /**
     * The properties that the component accepts.
     */
  props: {}
}
</script>
